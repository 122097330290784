@import "shared/styles/variables.scss";

.loading_icon_root {
  align-self: center;
}

.grabbi_loading_icon_root_black {
  color: $primary-black;
}

.grabbi_loading_icon_root_yellow {
  color: $primary-yellow;
}