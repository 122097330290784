@import "shared/styles/variables.scss";

.starter_kit_wrapper {
  background-color: $primary-white;

  .starter_kit_root {
    margin: 0 auto;
    text-align: center;
  }

  .divider {
    width: 80%;
    font-size: 2px;
    line-height: 2px;
    background-color: $primary-black;
    margin: 0 auto;
  }

  .grabbi_starter_kit_header_text {
    @include section_header_title_in_page;
    letter-spacing: normal;
    text-align: center;
    padding: 0px 40px 29px 40px;
  }

  .grabbi_starter_kit_sub_header_container {
    max-width: 804px;
    margin: 0 auto;
  }

  .grabbi_starter_kit_sub_header_text {
    @include sub_main_header;
    letter-spacing: normal;
    text-align: center;
    padding: 60px 40px;
  }

  .starter_kit_submit_button {
    background-color: $primary-purple;
    color: $primary-white;
    font-family: LemonMilkBold;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: normal;
    height: 56px;
    border-radius: 4px;
    width: 340px;
  }

  .starter_kit_submit_button:disabled {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.16);
    border: 4px solid rgba(0, 0, 0, 0.16);
  }

  .grabbi_starter_kit_body_text {
    font-family: Roboto-Medium;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: normal;
    text-align: center;
    padding: 0px 150px 29px 150px;
  }

  .grabbi_starter_kit_signup_section {
    font-family: Roboto-Medium;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: normal;
    text-align: center;
    padding: 0px 150px 29px 150px;
  }

  .grabbi_starter_kit_form {
    display: flex;
    column-gap: 8px;
    justify-content: center;
  }

  .grabbi_starter_kit_container {
    width: 426px;
    text-align: left;
    padding-bottom: 27px;
  }

  .grabbi_starter_kit_title {
    font-family: LemonMilkBold;
    font-size: 1.75rem;
    line-height: 1.75rem;
    letter-spacing: normal;
  }

  .grabbi_starter_kit_body_container {
    max-width: 485px;
    text-align: left;
    padding-bottom: 42px;
  }

  .grabbi_starter_kit_header_container {
    max-width: 804px;
    margin: 0 auto;
    padding-top: 24px;
  }

  .grabbi_starter_kit_sub_header_container {
    max-width: 804px;
    margin: 0 auto;
  }

  .starter_kit_section_header_text {
    color: $primary-white;
    font-family: LemonMilkBold;
    font-size: 2.25rem;
    line-height: 3rem;
    letter-spacing: normal;
    padding-bottom: 15px;
  }

  .starter_kit_section_body_text {
    color: $primary-white;
    font-family: Roboto-Regular;
    font-size: 1.75rem;
    line-height: 3rem;
    letter-spacing: normal;
    padding-bottom: 42px;
  }

  .starter_kit_section_sub_header_text {
    color: $primary-white;
    font-family: Roboto-Bold;
    font-size: 1.5rem;
    line-height: 3rem;
    letter-spacing: normal;
    padding-bottom: 12px;
  }

  .grabbi_starter_kit_section {
    @include sections_position;
    padding: 60px 0;
  }

  .section_with_large_menu {
    padding-bottom: 60px;
  }

  .grabbi_starter_kit_section_grid {
    @include sections_width_for_website;
    position: relative;
    z-index: 2;
    display: grid;
    grid-gap: 24px;
    justify-content: center;
    box-sizing: content-box;
    margin-bottom: 56px;
  }

  .grabbi_starter_kit_section_left_column {
    grid-column-start: 1;
    grid-column-end: 2;
    width: 360px;
    background-color: $primary-yellow;
  }

  .grabbi_starter_kit_section_middle_column {
    grid-column-start: 2;
    grid-column-end: 3;
    width: 360px;
    background-color: $primary-yellow;
  }

  .grabbi_starter_kit_section_right_column {
    grid-column-start: 3;
    grid-column-end: 3;
    width: 360px;
    background-color: $primary-yellow;
  }

  .grabbi_starter_kit_step_header_text_container {
    text-align: center;
    box-sizing: border-box;
    padding-bottom: 14px;
  }

  .grabbi_starter_kit_step_body_text_container {
    text-align: center;
    padding: 117px 14px;
  }

  .grabbi_starter_kit_step_body_text {
    @include grabbi_starter_kit_step_body_text;
  }

  .link_to_instagram {
    color: $primary-white;
    text-decoration: underline;
  }

  .partner_testimonials_section_background_container {
    @include sections_background_position;
    background-color: $primary-purple;
    height: 734px;
  }

  .partner_testimonials_body_container {
    padding-bottom: 38px;
  }

  .partner_testimonials_section {
    @include sections_position;
    max-width: $desktop-max-width-for-content;
    margin: 0 auto;
    box-sizing: content-box;
    text-align: center;
    padding-top: 60px;
  }

  .partner_testimonials_container {
    padding-bottom: 112px;
    display: inline-block;
  }

  .grabbi_partner_testimonials_grid {
    @include sections_width_for_website;
    position: relative;
    z-index: 2;
    display: grid;
    grid-gap: 55px;
    justify-content: center;
    box-sizing: content-box;
  }

  .grabbi_partner_testimonials_left_column {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .grabbi_partner_testimonials_right_column {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .grabbi_partner_testimonials_header_left_container,
  .grabbi_partner_testimonials_header_right_container {
    padding-bottom: 16px;
  }

  .grabbi_partner_testimonials_image {
    width: 563px;
    height: 363px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .grabbi_two_column_grid_in_starter_kit {
    @include sections_width_for_website;
    position: relative;
    z-index: 2;
    display: grid;
    grid-gap: 10px;
    justify-content: center;
    box-sizing: content-box;
    grid-template-columns: repeat(12,1fr);
  }

  .grabbi_two_column_grid_in_starter_kit_left_column {
    grid-column-start: 1;
    grid-column-end: 2;
    width: 530px;
    text-align: left;
    padding-left: 40px;
  }

  .grabbi_two_column_grid_with_large_image_left_column {
    grid-column-start: 1;
    grid-column-end: 2;
    width: 320px;
    text-align: left;
    padding-left: 40px;
  }

  .grabbi_two_column_grid_in_starter_kit_left_container {
    padding-top: 29px;
    padding-bottom: 40px;
  }

  .grabbi_two_column_grid_in_starter_kit_right_column {
    grid-column-start: 3;
    grid-column-end: 12;
  }

  .grabbi_two_column_grid_in_starter_kit_right_container {
  }

  .grabbi_starter_kit_section_header_text {
    @include grabbi_starter_kit_section_header_text;
    color: $primary-black;
  }

  .grabbi_starter_kit_section_body_text {
    @include grabbi_starter_kit_section_body_text;
    color: $primary-black;
  }

  .grabbi_starter_kit_image {
    width: 550px;
    height: 477px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .grabbi_starter_kit_large_menu_image {
    width: 750px;
    height: 1193px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .grabbi_three_column_grid_in_starter_kit {
    @include sections_width_for_website;
    position: relative;
    z-index: 2;
    display: grid;
    grid-gap: 80px;
    justify-content: center;
    box-sizing: content-box;
    padding: 60px 0;
  }

  .grabbi_three_column_grid_in_starter_kit_left_column {
    grid-column-start: 1;
    grid-column-end: 2;
    width: 350px;
  }

  .grabbi_three_column_grid_in_starter_kit_middle_column {
    grid-column-start: 2;
    grid-column-end: 3;
    width: 350px;
    margin-top: 48px;
  }

  .grabbi_three_column_grid_in_starter_kit_right_column {
    grid-column-start: 3;
    grid-column-end: 3;
    width: 350px;
    margin-top: 48px;
  }

 .grabbi_benefits_header {
    text-align: left;
    padding: 0px 0 0 28px;
  }

  .grabbi_three_column_grid_in_starter_kit_body_text_container {
    text-align: left;
    padding: 30px 14px 0px 14px;
  }

  .grabbi_benefits_body_text {
    @include grabbi_benefits_body_text;
    color: $primary-black;
    padding: 0px 0px 20px 0px;
  }

  .grabbi_texboxes_with_dark_background {
    background-color: $primary-charade !important;
    border-color: $primary-gray;
    color: $primary-white;
  }

  .grabbi_texbox_label_with_dark_background {
    background-color: $primary-charade !important;
    font-family: Roboto-Black;
    font-size: 16px;
    letter-spacing: normal;
    color: $primary-white;
  }

  .grabbi_label_with_dark_background_focused {
    background-color: $primary-charade !important;
    border-color: $primary-purple;
  }

  .grabbi_texbox_label_with_dark_background:focus {
    font-size: 14px !important;
  }

  .grabbi_texbox_label_with_dark_background_focused {
    color: $primary-white !important;
    font-size: 14px !important;
  }

  .tgrabbi_texbox_label_with_dark_background_disabled {
    opacity: 0.7;
  }

  .ready_to_start_section_in_starter_kit_background_container {
    @include sections_background_position;
    background-color: $primary-charade;
    height: 450px;
  }

  .ready_to_start_section {
    @include sections_position;
    max-width: $desktop-max-width-for-content;
    margin: 0 auto;
    box-sizing: content-box;
    text-align: center;
    padding: 100px 0 40px 0;
    height: 288px;
  }

  @media (max-width: 1279px) {
    .grabbi_starter_kit_section_grid,
    .grabbi_partner_testimonials_grid,
    .grabbi_two_column_grid_in_starter_kit,
    .grabbi_three_column_grid_in_starter_kit {
      max-width: 1024px;
      margin: 0 auto;
    }

    .grabbi_two_column_grid_in_starter_kit_right_column {
      grid-column-start: 3;
      grid-column-end: 6;
    }

    .grabbi_three_column_grid_in_starter_kit {
      grid-gap: 26px;
    }

    .grabbi_starter_kit_section_left_column,
    .grabbi_starter_kit_section_middle_column,
    .grabbi_starter_kit_section_right_column {
      width: 320px;
    }

    .grabbi_three_column_grid_in_starter_kit_left_column,
    .grabbi_three_column_grid_in_starter_kit_middle_column,
    .grabbi_three_column_grid_in_starter_kit_right_column {
      width: 325px;
    }

    .grabbi_two_column_grid_in_starter_kit_left_column,
    .grabbi_starter_kit_body_container {
      width: 350px;
      margin: 0 auto;
    }

    .grabbi_starter_kit_section_header_text {
      font-size: 1.5rem;
    }

    .grabbi_starter_kit_section_body_text {
      font-size: 1.25rem;
    }

    .grabbi_partner_testimonials_image {
      width: 420px;
    }

    .grabbi_starter_kit_image {
      width: 480px;
      height: 410px;
    }

    .grabbi_starter_kit_large_menu_image {
      width: 590px;
      height: 900px;
    }
  }

  @media (max-width: $ipad-breakpoint) {

    .grabbi_offering_section_grid {
      grid-gap: 0px;
      grid-template-columns: repeat(1, 1fr);
      padding: 60px 80px;
    }

    .grabbi_starter_kit_section_left_column {
      text-align: center;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    .grabbi_starter_kit_section_middle_column {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 2;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    .grabbi_starter_kit_section_right_column {
      grid-row-start: 3;
      grid-column-start: 1;
      grid-column-end: 2;
      text-align: center;
      margin: 0 auto;
    }

    .grabbi_partner_testimonials_grid {
      grid-gap: 0px;
      grid-template-columns: repeat(1, 1fr);
    }

    .grabbi_partner_testimonials_right_column {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 2;
    }

    .grabbi_two_column_grid_in_starter_kit {
      grid-gap: 0px;
      grid-template-columns: repeat(1, 1fr);
    }

    .grabbi_two_column_grid_in_starter_kit_left_column,
    .grabbi_two_column_grid_with_large_image_left_column {
      margin: 0 auto;
    }

    .grabbi_two_column_grid_in_starter_kit_right_column {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 2;
      margin: 0 auto;
    }

    .grabbi_two_column_grid_in_starter_kit {
      grid-gap: 0px;
      grid-template-columns: repeat(1, 1fr);
      padding: 60px 80px;
    }

    .grabbi_three_column_grid_in_starter_kit {
      grid-gap: 0px;
      grid-template-columns: repeat(1, 1fr);
    }

    .grabbi_three_column_grid_in_starter_kit_left_column {
      text-align: center;
      margin: 0 auto;
    }

    .grabbi_three_column_grid_in_starter_kit_middle_column {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 2;
      text-align: center;
      margin: 0 auto;
    }

    .grabbi_three_column_grid_in_starter_kit_right_column {
      grid-row-start: 3;
      grid-column-start: 1;
      grid-column-end: 2;
      text-align: center;
      margin: 0 auto;
    }

    .grabbi_three_column_grid_in_starter_kit_body_text_container {
      padding: 0px 14px 0px 14px;
    }

    .grabbi_partner_testimonials_image {
      width: 420px;
      margin: 0 auto;
    }

    .grabbi_partner_testimonials_header_left_container,
    .grabbi_partner_testimonials_header_right_container {
      padding-bottom: 0px;
    }

    .partner_testimonials_container {
      padding-bottom: 40px;
    }

    .partner_testimonials_section_background_container {
      height: 1024px;
    }

    .grabbi_starter_kit_section {
      padding-top: 20px;
    }

    .grabbi_two_column_grid_in_starter_kit {
      padding: 0px 20px;
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .grabbi_starter_kit_header_text {
      font-size: 3rem;
      line-height: 4.25rem;
      padding: 0px 10px 29px 10px;
    }

    .grabbi_starter_kit_form {
      flex-direction: column;
      align-items: center;
    }

    .grabbi_starter_kit_header_container {
      max-width: 560px;
    }

    .grabbi_starter_kit_section_header_text {
      font-size: 1.25rem;
    }

    .grabbi_partner_testimonials_image {
      width: 300px;
      height: 280px;
    }

    .grabbi_starter_kit_image {
      width: 300px;
      height: 245px;
    }

    .grabbi_starter_kit_large_menu_image {
      width: 300px;
      height: 445px;
    }

    .grabbi_starter_kit_section_left_column,
    .grabbi_starter_kit_section_middle_column,
    .grabbi_starter_kit_section_right_column {
      width: 300px;
    }

    .grabbi_three_column_grid_in_starter_kit_left_column,
    .grabbi_three_column_grid_in_starter_kit_middle_column,
    .grabbi_three_column_grid_in_starter_kit_right_column,
    .grabbi_two_column_grid_in_starter_kit_left_column,
    .grabbi_two_column_grid_with_large_image_left_column,
    .grabbi_starter_kit_body_container  {
      width: 298px;
    }

    .grabbi_starter_kit_body_text {
      font-size: 1.25rem;
      padding: 0px 70px 29px 70px;
    }

    .grabbi_starter_kit_signup_section {
      font-size: 1.25rem;
      padding: 0px;
    }

    .starter_kit_section_header_text {
      font-size: 2rem;
    }

    .starter_kit_section_body_text {
      font-size: 1.25rem;
    }

    .partner_testimonials_section_background_container {
      height: 960px;
    }

    .partner_testimonials_container {
      padding-bottom: 0px;
    }

    .grabbi_benefits_header {
      text-align: left;
      padding: 70px 0 0 0;
    }

    .grabbi_two_column_grid_in_starter_kit_left_column,
    .grabbi_two_column_grid_with_large_image_left_column {
      padding: 0 10px;
    }

    .starter_kit_submit_button {
      width: 310px;
    }

    .text_field_input,
    .text_field_root {
      width: 310px;
    }

    .ready_to_start_section_in_starter_kit_background_container {
      height: 430px;
    }

    .ready_to_start_section {
      padding: 60px 0 0 0;
      height: 370px;
    }

  }

  @media screen and (min-width: 401px) and (max-width: 500px) {
    .grabbi_starter_kit_header_container {
      max-width: 400px;
    }

    .grabbi_starter_kit_body_text {
      padding: 0px 10px 29px 10px;
    }

    .ready_to_start_section_in_starter_kit_background_container {
      height: 500px;
    }

    .ready_to_start_section {
      padding: 60px 0 0 0;
      height: 440px;
    }

  }

  @media (max-width: $mobile-portrait) {
    .grabbi_starter_kit_header_container {
      max-width: 390px;
    }

    .grabbi_starter_kit_header_text {
      font-size: 2.5rem;
    }

    .grabbi_starter_kit_body_text {
      font-size: 1rem;
      padding: 0px 30px 29px 30px;
    }

    .ready_to_start_section_in_starter_kit_background_container {
      height: 480px;
    }

    .ready_to_start_section {
      height: 420px;
    }
  }
}
