@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.rs_board_unavailable_checkbox_container {
    display: flex;
    align-items: center;
    .checkbox {
      padding-left: 0;
      color: $primary-black;
    }

    .checkbox_text {
      font-family: LemonMilkLight;
    }
  }