@import "shared/styles/variables.scss";

$desktop-width: 495px;
$mobile-width: 380px;

@mixin content-padding {
  padding-left: 24px;
  padding-right: 24px;
}

.edit_profile_manage_attendant_content {
  .loading {
    color: $primary-white;
  }

  .manage_attendant_subtitle {
    padding-bottom: 10px !important;
    margin-left: 34px;
  }

  .manage_attendant_button {
    cursor: pointer;
  }

  .manage_attendants_group_container {
    display: grid;
  }

  .attendant_group_header {
    padding: 0 34px;
    min-height: 40px;
    flex: 1 0 auto;
    padding-top: 0px;
    justify-content: space-between;
    align-items: center;
  }

  .attendant_group_header_text {
    padding: 18px 0;
    display: flex;
    float: left;
    font-family: LemonMilk;
    font-size: 14px;
    line-height: 18px;
  }

  .attendant_group_header_remove_link {
    display: flex;
    flex-direction: row;
    align-items: center;
    float: right;
    padding-top: 22px;
    cursor: pointer;
    text-decoration: underline;
    font-family: LemonMilk;
    font-size: 14px;
    line-height: 18px;
    color: $primary-purple;
  }

  .manage_attendants_container {
    border-bottom: 1px solid $primary-black;
    @include content-padding();
    min-height: 40px;
    flex: 1 0 auto;
    padding-top: 0px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child() {
      border-bottom: unset;
    }

    .title_container {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      max-width: 80%;
      padding: 0 5px;
    }

    .attendant_list_container {
      margin: 0 10px;
    }

    .button_container {
      display: flex;
      height: 2rem;
      width: 2rem;
    }
  }

  .manage_attendant_header_text {
    float: left;
    text-align: left;
    display: flex;
    width: $desktop-width;
    @include body_manage_attendant_text;
  }

  .manage_attendant_header_section_divider {
    display: block;
    width: $desktop-width;
    font-size: 1px;
    line-height: 2px;
    background-color: $primary-black;
    margin: 0 auto;
  }

  .manage_attendant_row_section_divider {
    display: block;
    width: $desktop-width;
    font-size: 1px;
    line-height: 2px;
    background-color: $primary-dark-gray;
    margin: 0 auto;
    opacity: .2;
  }

  .columns {
    margin: 22px 0px;
    display: flex;
    width: $desktop-width;
  }

  .left_column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;
    margin-right: 80px;
    text-align: center;
    @include body_manage_attendant_text;
  }

  .right_column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 60%;
  }

  .add_more_attendant_button_text {
    color: $primary-black;
    font-family: LemonMilkBold;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: normal;
    vertical-align: middle;
    display: inline-block;
  }

  .add_more_attendant_button {
    cursor: pointer;
    color: $primary-black;
    height: 1.75rem;
    width: 1.75rem;
    vertical-align: middle;
    display: inline-block;
    margin: 20px 0;
  }

  .save_attendant_button_container {
    text-align: center;
  }

  .save_attendant_button {
    background-color: $primary-purple;
    color: $primary-white;
    font-family: LemonMilkBold;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: normal;
    height: 56px;
    border-radius: 4px;
    width: 90%;
    margin: 30px 10px;
  }

  .save_action_button {
    margin: 20px 0;
    width: 100%;
  }

  .add_more_attendant_button_container {
    border-bottom: 1px solid $primary-black;
  }

  @media (max-width: $mobile-breakpoint) {
    .manage_attendant_header_text {
      width: $mobile-width;
    }

    .columns {
      display: inline-table;
      width: $mobile-width;
    }

    .manage_attendant_header_section_divider {
      width: $mobile-width;
    }

    .left_column {
      @include body_manage_attendant_text;
    }

    .right_column {
      width: 100%;
    }
  }
}