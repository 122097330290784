@import "shared/styles/variables.scss";

.forgot_password_root {
  display: flex;
  flex-direction: column;
  align-items: center;

  .forgot_password_title {
    font-family: LemonMilkBold;
    font-weight: 700;
    font-size: 44px;
    margin-bottom: 50px;
  }

  .forgot_password_button {
    margin-top: 0px;
    width: 336px;
  }
}