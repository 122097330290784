@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.close_customer_tab_root {
  .close_dialog_paper {
    align-self: flex-end;
    margin: 0;
    min-width: 100vw;
    border-radius: 0;

    .close_dialog_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        bottom: 0;

        .change_tip_button {
          margin-right: 1rem;
        }
    
        .row_container {
          width: 100%;
        }
    
        .row {
          display: flex;
          justify-content: space-between;
          font-weight: 600;
          padding: 0.75rem 0;
          border-bottom: 1px solid black;
          width: 100%;
        }
    
        .row:last-child {
          border-bottom: unset;
        }
    
        .divider_text {
          align-self: flex-start;
          font-weight: 700;
          margin-bottom: 1rem;
        }
    
        .cashier_checkout_button {
          max-width: 350px;
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0 1rem;
    
          .cashier_checkout_icon {
            margin-right: 1rem;
            background-image: url('/assets/cashier-checkout.svg');
            background-position: center;
            background-size: cover;
            height: 30px;
            width: 30px;
          }
        }
      }
  }
}