@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.product_pairing_display_root {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 50px;
  .title {
    font-family: LemonMilkBold;
    font-size: 26px;
    color: $primary-white;
  }

  .selection_area {
    display: flex;
    align-items: center;
    padding: 60px 80px;
    @media (max-width: 1100px) {
      flex-direction: column;
      align-items: center;
    }
    .selections {
      .selection {
        cursor: pointer;
        font-family: LemonMilk;
        font-size: 18px;
        color: $primary-white;
        padding: 10px 20px;
        margin: 20px 0;
        border-radius: 25px;
      }
      .selection.selected {
        background-color: $primary-yellow;
        color: $primary-black;
      }
      .selection:hover {
        background-color: rgba($primary-yellow, 0.2);
      }
    }

    .venn_diagram_area {
      width: 66%;
      display: flex;
      justify-content: center;
    }
  }

  .tip_area {
    align-self: flex-start;
    .tip_title {
      font-family: LemonMilkBold;
      font-size: 30px;
      margin-bottom: 40px;
    }

    .tip_content {
      display: flex;
        .grabbi_icon {
          background-image: url("/assets/grabbi-round.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-color: $primary-yellow;
          border-radius: 30px;
          width: 60px;
          height: 60px;
        }
      .text_wrapper {
        margin-left: 15px;

        p {
          font-family: LemonMilk;
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .selection_area_column {
    flex-direction: column;
  }
}
