@import "../../shared/styles/variables.scss";

$column-breakpoint: 960px;

.homepage_container {
  background-color: $primary-white;
}

.section_divider {
  display: block;
}

.divider {
  width: 80%;
  font-size: 2px;
  line-height: 2px;
  background-color: $primary-black;
  margin: 0 auto;
}

.header_container {
  @include sections_width_for_website;
}

.main_header_text_and_logo_container {
  @include sections_width_for_website;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  display: grid;
  grid-gap: 0px;
  justify-content: center;
  padding: 80px 80px 30px 80px;
  box-sizing: content-box;
}

.main_header_text {
  font-family: 'LemonMilkBold';
  color: $primary-black;
  font-size: 5.25rem;
  line-height: 5.85rem;
  grid-column-start: 1;
  grid-column-end: 2;
  max-width: 753px;
  text-align: center;
}

.sub_header_text_container {
  text-align: center;
}

.sub_header_text {
  @include sub_main_header;
  letter-spacing: 4px;
}

.signup_header_button_container {
  padding: 80px 0px 80px 0px;
}

.signup_header_button {
  @include navigation_schedule_button_text;
  background-color: $primary-purple;
  color: $primary-white;
  padding: 17px 32px;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 80px;
}

.padding_for_content {
  padding-left: 80px;
}

.main_header_logo_container {
  z-index: 2;
  grid-column-start: 2;
  grid-column-end: 3;
  width: 200px;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/assets/grabbi-tag-logo.png");
}

.online_store_icons {
  cursor: pointer;
}

.app_stores_image_container {
  height: 93px;
  display: flex;
  margin-right: 13px;
}

.apple_store_icon {
  width: 270px;
  height: 100%;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  padding-right: 20px;
  margin-right: 13px;
}

.google_play_icon {
  width: 362px;
  height: 100%;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}

.grabbi_offering_section {
  @include sections_width_for_website;
  position: relative;
  z-index: 2;
  padding-top: 40px;
}

.grabbi_offering_section_header_text_container {
  padding-left: 60px;
}

.grabbi_offering_section_header_text {
  @include grabbi_offering_section_header_text;
}

.grabbi_offering_section_grid {
  @include sections_width_for_website;
  position: relative;
  z-index: 2;
  display: grid;
  grid-gap: 20px;
  justify-content: center;
  padding: 60px 120px;
  box-sizing: content-box;
}

.grabbi_offering_section_left_column {
  background-color: $primary-yellow;
  grid-column-start: 1;
  grid-column-end: 2;
  width: 360px;
  height: 380px;
}

.grabbi_offering_section_middle_column {
  background-color: $primary-yellow;
  grid-column-start: 2;
  grid-column-end: 3;
  width: 360px;
  height: 380px;
}

.grabbi_offering_section_right_column {
  background-color: $primary-yellow;
  grid-column-start: 3;
  grid-column-end: 3;
  width: 360px;
  height: 380px;
}

.grabbi_feature_header_text_container {
  height: 286px;
  text-align: center;
  box-sizing: border-box;
  padding: 73px 17px 80px 17px;
}

.grabbi_feature_header_text {
  @include grabbi_feature_header_text;
}

.grabbi_feature_body_text_container {
  text-align: center;
  padding: 0 40px;
}

.grabbi_feature_body_text {
  @include grabbi_feature_body_text;
}

.merchant_schedule_demo_section {
  @include sections_position;
  padding: 80px 20px;
}

.merchant_schedule_demo_section_grid {
  @include sections_width_for_website;
  position: relative;
  z-index: 2;
  display: grid;
  grid-gap: 95px;
  justify-content: center;
  padding: 0px 80px;
  box-sizing: content-box;
}

.merchant_schedule_demo_left_column {
  grid-column-start: 1;
  grid-column-end: 2;
  max-width: 465px;
}

.merchant_schedule_demo_header_left_container {
  padding-top: 29px;
  padding-bottom: 37px;
}

.merchant_schedule_demo_right_column {
  grid-column-start: 2;
  grid-column-end: 3;
}

.grabbi_signup_video {
  width: 528px;
  height: 304px;
  box-sizing: border-box;
  border: 8px solid $primary-black;
}

.grabbi_starter_kit_image {
  width: 528px;
  height: 362px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.schedule_demo_body_container {
  padding-bottom: 89px;
}

.schedule_demo_body_container_text {
  @include schedule_demo_body_container_text;
}

.merchant_schedule_demo_header_text {
  @include merchant_schedule_demo_content_text;
  color: $primary-black;
}

.schedule_demo_button {
  @include navigation_schedule_button_text;
  background-color: $primary-purple;
  color: $primary-white;
  padding: 17px 32px;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 80px;
}

.store_insight_section {
  @include sections_position;
  padding-top: 80px;
}

.store_insight_section_grid {
  @include sections_width_for_website;
  position: relative;
  z-index: 2;
  display: grid;
  grid-gap: 95px;
  justify-content: center;
  padding: 0px 220px;
  box-sizing: content-box;
}

.store_insight_left_column {
  grid-column-start: 1;
  grid-column-end: 2;
  width: 465px;
}

.store_insight_right_column {
  grid-column-start: 2;
  grid-column-end: 3;
  max-width: 480px;
}

.store_insight_vip_icon_container {
  z-index: 1;
}

.store_insight_vip_icon {
  height: 155px;
  width: 115px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.store_items_insight_icon_container {
  z-index: 1;
}

.in_store_shopping_header_container {
  text-align: center;
  padding-bottom: 26px;
}

.in_store_shopping_header_text {
  font-family: 'Roboto-Medium';
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: $primary-black;
}

.store_items_insight_icon {
  height: 342px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0px 10px;
}

.store_insight_header_right_container {
  padding-bottom: 37px;
}

.store_insight_header_text {
  @include merchant_schedule_demo_content_text;
}

.store_insight_body_container {
  padding-bottom: 89px;
}

.store_insight_body_container_text {
  @include schedule_demo_body_container_text;
}

.store_insight_signup_button_container {
  padding-bottom: 89px;
}

.store_insight_signup_button {
  @include navigation_schedule_button_text;
  background-color: $primary-purple;
  color: $primary-white;
  padding: 17px 32px;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 80px;
}

.install_app_section {
  @include sections_position;
  padding-bottom: 100px;
  padding-top: 80px;
}

.install_app_section_grid {
  @include sections_width_for_website;
  position: relative;
  z-index: 2;
  display: grid;
  grid-gap: 95px;
  justify-content: center;
  padding: 0px 220px;
  box-sizing: content-box;
}

.install_app_left_column {
  grid-column-start: 1;
  grid-column-end: 2;
  padding-top: 32px;
  width: 465px;
}

.install_app_right_column {
  grid-column-start: 2;
  grid-column-end: 3;
  max-width: 480px;
}

.install_app_payment_and_proof_on_mobile_container {
  padding-left: 0px;
  z-index: 2;
  top: 42px;
}

.install_app_payment_and_proof_on_mobile_icon {
  height: 538px;
  width: 450px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.grabbi_menu_qr_code {
  height: 295px;
  width: 295px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.install_app_header_right_container {
  padding-bottom: 37px;
  max-width: 240px;
}

.install_app_header_text {
  @include merchant_schedule_demo_content_text;
  color: $primary-black;
}

.install_app_body_container {
  padding-bottom: 42px;
}

.install_app_body_container_text {
  @include schedule_demo_body_container_text;
}

.install_app_buttons_container {
  padding-bottom: 89px;
}

.install_app_signup_button {
  @include navigation_schedule_button_text;
  background-color: $primary-purple;
  color: $primary-white;
  padding: 17px 32px;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 80px;
}

.grabbi_pay_screen {
  height: 660px;
  top: 39px;
  width: 305px;
  padding: 0 15px;
  background-size: cover;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
}

.ready_to_start_section_background_container {
  @include sections_background_position;
  background-color: $primary-charade;
  height: 388px;
}

.ready_to_start_section {
  @include sections_position;
  max-width: $desktop-max-width-for-content;
  margin: 0 auto;
  box-sizing: content-box;
  text-align: center;
  padding-top: 100px;
  height: 288px;
}

.ready_to_start_container {
  padding-bottom: 50px;
  display: inline-block;
}

.ready_to_start_header_container {
  padding-bottom: 19px;
}

.ready_to_start_body_container {
  padding-bottom: 38px;
}

.ready_to_start_header_text {
  @include feature_header_content_text;
  color: $primary-white;
}

.ready_to_start_body_text {
  font-family: 'Roboto-Medium';
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: $primary-white;
}

.ready_to_start_button_container {
  margin-top: 38px;
}

.ready_to_start_button {
  @include navigation_schedule_button_text;
  background-color: $primary-purple;
  color: $primary-white;
  padding: 12px 72px;
  box-sizing: border-box;
  text-decoration: none;
  border-radius: 80px;
}

@media (max-width: $tablet-breakpoint) {
  .main_header_text {
    font-size: 4.623rem;
    width: 663px;
  }

  .sub_header_text {
    font-size: 1.25rem;
    line-height: 2.5rem;
  }

  .grabbi_offering_section_grid {
    grid-gap: 8px;
  }

  .grabbi_offering_section_left_column,
  .grabbi_offering_section_right_column,
  .grabbi_offering_section_middle_column {
    width: 330px;
  }

  .merchant_schedule_demo_section_grid {
    padding: 0px 60px;
  }

  .store_insight_signup_button_container {
    padding-bottom: 48px;
  }
}

@media (max-width: $ipad-breakpoint) {
  .main_header_text {
    font-size: 3.5rem;
    width: 502px;
  }

  .header_container {
    @include sections_width_for_website;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header_text_container {
    max-width: 260px;
    padding-top: 6px;
  }

  .main_header_text_and_logo_container {
    padding: 40px 0px 30px 0px;
  }

  .grabbi_offering_section_header_text_container {
    text-align: center;
    padding: 0px 10px;
  }

  .grabbi_offering_section_grid {
    grid-gap: 0px;
    grid-template-columns: repeat(1, 1fr);
    padding: 60px 80px;
  }

  .grabbi_offering_section_left_column {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .grabbi_offering_section_middle_column {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 2;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .grabbi_offering_section_right_column {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    text-align: center;
    margin: 0 auto;
  }

  .grabbi_signup_video {
    width: 315px;
    height: 184px;
  }

  .grabbi_starter_kit_image {
    width: 315px;
    height: 210px;
  }

  .store_insight_section_grid,
  .install_app_section_grid,
  .merchant_schedule_demo_section_grid {
    grid-gap: 32px 0;
    grid-template-columns: repeat(1, 1fr);
  }

  .merchant_schedule_demo_right_column,
  .merchant_schedule_demo_left_column,
  .store_insight_right_column,
  .store_insight_left_column,
  .install_app_right_column,
  .install_app_left_column,
  .ready_to_start_header_container {
    text-align: center;
    margin: 0 auto;
    max-width: 318px;
  }

  .schedule_demo_body_container_text,
  .store_insight_body_container_text,
  .install_app_body_container_text,
  .ready_to_start_body_text {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .store_insight_right_column,
  .merchant_schedule_demo_right_column,
  .store_insight_right_column,
  .install_app_right_column {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .install_app_left_column,
  .store_insight_left_column {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .install_app_payment_and_proof_on_mobile_icon {
    width: 318px;
    height: 400px;
  }

  .grabbi_menu_qr_code {
    height: 200px;
    width: 200px;
    margin: 0 auto;
  }

  .install_app_mobile_payment_container {
    padding-left: 22px;
    top: 28px;
  }

  .app_stores_image_container {
    height: 60px;
    display: block;
  }

  .install_app_left_column {
    padding-top: 50px;
  }

  .install_app_body_container {
    text-align: center;
    justify-content: center;
    max-width: 300px;
  }

  .apple_store_icon {
    width: 194px;
    border-radius: 8px;
    margin: 0 auto;
    background-size: cover;
    padding-right: 0px;
  }

  .google_play_icon {
    width: 196px;
    margin: 0 auto;
    padding-top: 16px;
  }

  .store_insight_header_right_container,
  .store_insight_body_container,
  .install_app_header_right_container {
    text-align: center;
    margin: 0 auto;
  }

  .merchant_schedule_demo_header_text,
  .store_insight_header_text,
  .install_app_header_text,
  .ready_to_start_header_text {
    font-size: 1.75rem;
    line-height: 2.3rem;
  }

  .merchant_schedule_demo_section,
  .store_insight_section,
  .install_app_left_column {
    padding-top: 0px;
  }

  .schedule_demo_body_container,
  .store_insight_body_container,
  .ready_to_start_body_container {
    padding-bottom: 40px;
  }

  .merchant_schedule_demo_right_column,
  .store_items_insight_icon_container,
  .install_app_section {
    padding-bottom: 0px;
  }

  .install_app_section {
    padding-top: 40px;
  }

  .install_app_right_column {
    padding-top: 30px;
  }

  .ready_to_start_section {
    padding-top: 70px;
  }
}

@media (max-width: $mobile-breakpoint) {

  .main_header_text_and_logo_container {
    box-sizing: content-box;
  }

  .main_header_text {
    font-size: 2rem;
    line-height: 3rem;
    width: 290px;
  }

  .main_header_logo_container {
    width: 80px;
    height: 80px;
  }

  .signup_header_button_container {
    padding: 80px 0px 50px 0px;
  }

  .section_divider {
    display: block;
  }

  .divider {
    width: 80%;
    font-size: 2px;
    line-height: 2px;
    background-color: $primary-black;
    margin: 0 auto;
  }

  .store_insight_section_grid,
  .install_app_section_grid,
  .merchant_schedule_demo_section_grid {
    padding: 32px 20px;
  }
}

@media (max-width: $mobile-portrait) {
  .main_header_text_and_logo_container {
    grid-gap: 0px;
  }

  .main_header_text {
    width: 230px;
  }

  .main_header_logo_container {
    z-index: 2;
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .grabbi_offering_section_header_text {
    font-size: 2rem;
    line-height: 3rem;
  }

  .grabbi_offering_section_left_column,
  .grabbi_offering_section_middle_column,
  .grabbi_offering_section_right_column {
    width: 320px;
  }
}