@import "shared/styles/variables.scss";

.loggedout_root {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.loggedout_header_text {
  font-family: LemonMilkBold;
  font-size: 54px;
  line-height: 62px;
  max-width: 885px;
  text-align: center;
  letter-spacing: normal;
}

.loggedout_message_text {
  margin-top: 40px;
  font-family: Roboto-Bold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  letter-spacing: normal;
  max-width: 550px;
}

.loggedout_login_button {
  margin-top: 40px;
  background-color: $primary-purple;
  color: $primary-white;
  font-family: LemonMilkBold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: normal;
  height: 56px;
  border-radius: 4px;
  width: 336px;
}