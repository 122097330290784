@import "shared/styles/variables.scss";

.logout_root {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}

.logout_text {
  font-family: LemonMilkBold;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: normal;
}
