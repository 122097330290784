@import "shared/styles/variables.scss";

.signup_wrapper {
  .signup_root {
    display: flex;
    flex-direction: column;
    width: 336px;
    margin: 0 auto;
    text-align: center;
    padding-top: 128px;
  }

  .show_merchant_info_details,
  .show_continue_signup_button,
  .show_signup_button {
    display: '';
  }

  .hide_merchant_info_details,
  .hide_continue_signup_button,
  .hide_signup_button {
    display: none;
  }

  .signup_error {
    font-family: LemonMilkBold;
    font-size: 16px;
    line-height: 18.75px;
    color: $primary-purple;
    text-align: left;
  }

  .signup_form_error {
    font-family: LemonMilkBold;
    color: $primary-purple;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
  }

  .signup_title {
    font-family: LemonMilkBold;
    font-size: 2rem;
    line-height: 3.87rem;
    letter-spacing: normal;
    margin-bottom: 15px;
  }

  .signup_sub_title {
    font-family: LemonMilkBold;
    font-size: 1rem;
    line-height: 1.12rem;
    letter-spacing: normal;
    margin-bottom: 51px;
  }

  .signup_error_message {
    height: 33px;
    margin-bottom: 15px;
    text-align: center;
  }

  .signup_text_field {
    height: 56px;
    width: 100%;
  }

  .signup_form {
    width: 100%;
  }

  .signup_form_group_root {
    margin-bottom: 20px;
  }

  .phone_number_field {
    margin-bottom: 40px;
  }

  .signup_form_helper_text_root {
    font-family: LemonMilkBold;
    color: $primary-purple;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: normal;
  }

  .signup_reset_password_link {
    cursor: pointer;
    font-family: LemonMilkBold;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: normal;
    color: $primary-black;
    text-decoration: underline;
    font-weight: 700;
    margin-left: 20px;
    margin-bottom: 25px;
    align-self: flex-start;
  }

  .signup_subtext_link {
    color: $primary-black;
    font-family: Roboto-Regular;
    text-decoration: underline;
    font-weight: 600;
    font-size: .85rem;
    line-height: 1.5rem;
  }

  .signup_subtext {
    font-family: Roboto-Regular;
    margin-bottom: 27px;
    text-align: left;
    font-size: .85rem;
    line-height: 1.5rem;
  }

  .signup_helper_text_root {
    font-family: LemonMilkBold;
    color: $primary-purple;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: normal;
    margin-bottom: 10px;
    align-self: flex-start;
  }

  .signup_submit_button,
  .continue_signup {
    background-color: $primary-purple;
    color: $primary-white;
    font-family: LemonMilkBold;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: normal;
    height: 56px;
    border-radius: 4px;
    width: 100%;
  }

  .signup_submit_button:disabled,
  .continue_signup:disabled {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.16);
    border: 4px solid rgba(0, 0, 0, 0.16);
  }

  .seperator {
    display: flex;
    align-items: center;
    padding: 64px 0px 52px 0px;
    div {
      background-color: #000000;
      height: 2px;
      width: 45%;
    }
    p {
      padding: 0 10px;
      font-family: LemonMilk;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .social_sign_in {
    width: 100%;
    .social_button {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      border-radius: 50px !important;
    }

    .google_sign_in {
      width: 100%;
      height: 50px;
      opacity: 1 !important;
      background-color: #ffffff !important;
      color: #000000 !important;
      border: 2px solid $primary-black !important;
      span {
        font-family: LemonMilkBold;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 2px;
      }
    }

    .apple_sign_in {
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
      border-radius: 2px;
      background-color: #000000;
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      .apple_icon {
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        margin-right: 15px;
      }
      .text {
        font-size: 12px;
        color: #ffffff;
        font-family: LemonMilkBold;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 2px;
      }
    }
    .apple_sign_in:active,
    .apple_sign_in:focus {
      border: 1px black solid;
    }
  }

  .login_link_container {
    width: 320px;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 50px;
  }

  .have_an_account_text {
    font-family: Roboto-Regular;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: normal;
  }

  .login_link {
    cursor: pointer;
    font-family: Roboto-Medium;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 700;
    letter-spacing: normal;
    color: $primary-black;
    text-decoration: none;
  }
}
