@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.payment_success_root {
  .header {
    font-family: LemonMilk;
    padding: 1rem;
    position: sticky;
    top: 0;
    background-color: $primary-yellow;
    display: flex;
    justify-content: space-between;

    .store_title {}
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;

    .paid_card {
      display: flex;
      align-items: center;
      background-color: $primary-white;
      padding: 1rem;
      border-radius: 20px;
      width: 100%;
      max-width: 350px;
      margin-bottom: 1rem;

      .left {
        width: 50%;
        padding-right: 1rem;

        .paid {
          font-family: LemonMilk;
        }

        .thank_you {
          font-weight: 600;
        }
      }

      .right {
        width: 50%;
        padding-right: 1rem;

        .view_order {
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 20px;

          color: $primary-white;
          padding: 1rem 0.5rem;

          .order_code {
            font-family: LemonMilk;
            font-size: 1.7rem;
          }

          .view_order_text {
            font-weight: 600;
          }
        }
      }


    }

    .email_card {
      display: flex;
      flex-direction: column;
      background-color: $primary-white;
      padding: 1rem;
      border-radius: 20px;
      width: 100%;
      max-width: 350px;

      .email_text {
        font-weight: 700;
      }

      .description_text {
        font-weight: 600;
      }
    }
  }


}