@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.grabbi_multi_select_dropdown_error {
  font-family: LemonMilkBold;
  color: $primary-purple;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

.grabbi_multi_select_dropdown_wrapper {
  width: 290px;
  .grabbi_multi_select_dropdown_root {
    color: $primary-black;
    padding: 10px 15px;
    width: 100%;
    height: 50px;
    font-family: Roboto-Black;
    border: 2px solid $primary-black;
    svg {
      color: $primary-black;
      margin-right: 10px;
    }
  }

  .grabbi_multi_select_dropdown_root:hover {
    border-color: $primary-purple;
  }

  .labeled {
    height: 65px;
    .MuiSelect-root {
      margin-left: -17px;
    }
  }

  .label {
    font-family: Roboto-Black;
    font-size: 16px;
    color: $primary-black;
    padding: 0 3px;
    margin-left: 10px;
  }

  .MuiInputLabel-shrink {
    margin-top: 3px;
  }

  .MuiInput-underline:after {
    border-bottom: unset !important;
  }

  .Mui-focused {
    border: unset !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 2px solid $primary-black;
  }
}

.grabbi_multi_select_dropdown_wrapper.disabled {
  opacity: 0.3;

  .grabbi_multi_select_dropdown_root:hover {
    border-color: $primary-black !important;
  }
}

.grabbi_multi_select_dropdown_input {
  width: 100%;
}

.grabbi_multi_select_dropdown_menu {
  color: $primary-black;
  background-color: $primary-yellow;
  border: 1px solid $primary-black;
  ul {
    li {
      font-family: Roboto-Black;
    }
  }
}
