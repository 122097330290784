@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.cart_root {
  .cart_header{
    display: flex;
    align-items: center;
    font-family: LemonMilkBold;
    padding: 0 1rem;

    .store_name {
      margin-left: 2rem;
    }
  }
  .cart_item_container {
    padding: 1.5rem 1rem;
    .cart_item_root {
        margin-bottom: 1rem;
        border-bottom: 1px solid $primary-black;
        padding: 0.5rem 0;
      }
    
      .cart_item_root:last-child {
        border-bottom: unset;
      }
  }
  
  .empty_cart_message {
    font-family: LemonMilk;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 10rem;
  }
}