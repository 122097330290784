$primary-yellow: #fff100;
$primary-black: #000000;
$primary-white: #ffffff;
$primary-purple: #820dff;
$primary-blue: #01a3ee;
$primary-orange: #ee5c01;
$primary-red: #d32479;
$primary-green: #00a22b;
$primary-charade: #2F2F3D;
$primary-gray: #BDBDBD;
$primary-light-gray: #EEEEEE;
$primary-dark-gray: #5C5C5C;
$primary-disabled: rgba(15, 12, 12, 0.16);


$mobile-breakpoint: 735px;
$desktop-max-width-for-content: 1280px;
$desktop-max-width-for-narrow-content: 1240px;
$desktop-max-width: 1440px;
$tablet-breakpoint: 1199px;
$ipad-breakpoint: 1023px;
$mobile-portrait: 400px;

//MUI Breakpoints
$mui-xs: 0px;
$mui-sm: 600px;
$mui-md: 960px;
$mui-lg: 1200px;
$mui-xl: 1536px;

@mixin sections_width {
  max-width: $desktop-max-width;
  margin: 0 auto;
}

@mixin sections_width_for_website {
  max-width: $desktop-max-width-for-narrow-content;
  margin: 0 auto;
}

@mixin sections_padding_mobile {
  @media (max-width: $desktop-max-width-for-content) {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}

@mixin sections_position {
  position: relative;
  z-index: 2;
}

@mixin sections_background_position {
  position: absolute;
  z-index: 1;
  width: 100%;
}

@mixin main_header_title_in_page {
  font-family: 'LemonMilkBold';
  color: $primary-black;
  font-size: 5.5rem;
  line-height: 5.5rem;
}

@mixin main_header_title_in_page_for_mobile {
  font-family: 'LemonMilkBold';
  font-size: 2.75rem;
  line-height: 2.75rem;
}

@mixin section_header_title_in_page {
  font-family: 'LemonMilkBold';
  color: $primary-black;
  font-size: 5rem;
  line-height: 5.75rem;
}

@mixin sub_main_header {
  font-family: 'LemonMilk';
  font-size: 1.5rem;
  line-height: 3rem;
}

@mixin section_header_title_in_mobile_page {
  font-family: 'LemonMilkBold';
  color: $primary-black;
  font-size: 2.5rem;
  line-height: 2.75rem;
}

@mixin feature_header_content_text {
  font-family: 'LemonMilkBold';
  font-size: 1.8rem;
  line-height: 2.6rem;
}

@mixin grabbi_partners_section_header_text {
  color: $primary-black;
  font-family: 'Roboto-Medium';
  font-size: 2.25rem;
  line-height: 3rem;
}

@mixin grabbi_offering_section_header_text {
  color: $primary-black;
  font-family: 'LemonMilkBold';
  font-size: 2.75rem;
  line-height: 3.25rem;
}

@mixin merchant_schedule_demo_content_text {
  color: $primary-black;
  font-family: 'LemonMilkBold';
  font-size: 3rem;
  line-height: 3.4rem;
}

@mixin grabbi_starter_kit_section_header_text {
  font-family: 'LemonMilkBold';
  font-size: 2rem;
  line-height: 3rem;
}

@mixin grabbi_getstarted_section_header_text {
  font-family: 'Roboto-Regular';
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 700;
}

@mixin grabbi_getstarted_section_body_text {
  font-family: 'Roboto-Regular';
  font-size: 1.75rem;
  line-height: 3rem;
}

@mixin grabbi_getstarted_offer_section_header_text {
  font-family: 'LemonMilk';
  font-size: 3.5rem;
  line-height: 2.5rem;
  font-weight: 400;
}

@mixin grabbi_getstarted_offer_section_body_text {
  font-family: 'LemonMilkBold';
  font-size: 1.5rem;
  line-height: 2.5rem;
}

@mixin grabbi_starter_kit_section_body_text {
  font-family: 'Roboto-Regular';
  font-size: 1.5rem;
  line-height: 2.5rem;
}

@mixin feature_header_mobile_content_text {
  font-family: 'LemonMilkBold';
  font-size: 1.5rem;
  line-height: 2.1rem;
}

@mixin second_header_content_text {
  font-family: 'LemonMilkBold';
  font-size: 3rem;
  line-height: 3.4rem;
}

@mixin body_content_text {
  font-family: 'Roboto-Bold';
  font-size: 1.5rem;
  line-height: 2.1rem;
}

@mixin schedule_demo_body_container_text {
  font-family: 'Roboto-Regular';
  color: $primary-black;
  font-size: 1.5rem;
  line-height: 2rem;
}

@mixin navigation_schedule_button_text {
  font-family: 'LemonMilkBold';
  font-size: 1rem;
  line-height: 1.2rem;
}

@mixin navigation_button_text_for_mobile {
  font-family: 'LemonMilk';
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin grabbi_feature_header_text {
  font-family: 'LemonMilkBold';
  font-size: 1.75rem;
  line-height: 2rem;
}

@mixin grabbi_feature_body_text {
  font-family: 'LemonMilk';
  font-size: 1rem;
  line-height: 1.25rem;
}

@mixin grabbi_starter_kit_step_body_text {
  font-family: 'Roboto-Bold';
  font-size: 1.75rem;
  line-height: 2.25rem;
}

@mixin grabbi_benefits_body_text {
  font-family: 'Roboto-Regular';
  font-size: 1.5rem;
  line-height: 2.25rem;
}

@mixin body_text_in_sections {
  font-family: 'Roboto-Bold';
  font-size: 1.25rem;
  line-height: 2.25rem;
}

@mixin navigation_schedule_button_text {
  font-family: 'LemonMilkBold';
  font-size: 1rem;
  line-height: 1.2rem;
}

@mixin schedule_demo_body_container_text {
  font-family: 'Roboto-Regular';
  color: $primary-black;
  font-size: 1.5rem;
  line-height: 2rem;
}

@mixin body_mobile_content_text {
  font-family: 'Roboto-Bold';
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin setup_printe_header {
  font-family: LemonMilkBold;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: normal;
}

@mixin body_setup_printer_text {
  font-family: 'Roboto-Bold';
  font-size: 1rem;
  line-height: 1.5rem;
}

@mixin body_manage_attendant_text {
  font-family: 'Roboto-Bold';
  font-size: 1rem;
  line-height: 1.5rem;
}
