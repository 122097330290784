@import "shared/styles/variables.scss";

$two-column-breakpoint: 960px;
$one-column-breakpoint: 484px;

.footer_root {
  padding: 40px 80px 64px 80px;
  background-color: $primary-black;
}

.grabbi_icon_for_footer_container {
  display: flex;
  padding-top: 80px;
  padding-bottom: 20px;
}

.footer_section_divider {
  display: block;
}

.footer_section_divider {
  width: 100%;
  font-size: 1px;
  line-height: 2px;
  background-color: $primary-dark-gray;
  margin: 0 auto;
}

.grabbi_icon_for_footer {
  height: 17px;
  width: 127px;
  background-repeat: no-repeat;
  background-size: contain;
}

.grabbi_spitfier_for_footer  {
  height: 54px;
  width: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding-bottom: 1px;
}

.grabbi_culture_container {
  align-self: center;
  margin-left: 26px;
}

.grabbi_culture_container_text {
  font-family: 'Roboto-Regular';
  font-size: 0.8rem;
  line-height: 1rem;
  color: $primary-gray;
}

.footer_links_section_grid {
  position: relative;
  z-index: 2;
  display: grid;
  grid-gap: 20px;
  padding-top: 2px;
  box-sizing: content-box;
  grid-template-columns: repeat(3, 1fr);
}

.footer_links_left_column {
  grid-column-start: 1;
  grid-column-end: 2;
  width: 140px;
}

.footer_links_middle_column {
  grid-column-start: 2;
  grid-column-end: 3;
  width: 140px;
}

.footer_links_right_column {
  grid-column-start: 3;
  grid-column-end: 3;
  width: 120px;
}

.footer_links_header_text {
  font-family: 'Roboto-Medium';
  font-size: 1rem;
  line-height: 1.25rem;
  color: $primary-white;
  padding-bottom: 30px;
}

.second_level_header {
  padding-top: 39px;
}

.footer_links_item_text {
  font-family: 'Roboto-Medium';
  font-size: .75rem;
  line-height: .85rem;
  color: $primary-gray;
  cursor: pointer;
}

.footer_links_item_text_links {
  display: block;
  font-family: 'Roboto-Medium';
  font-size: .75rem;
  line-height: .85rem;
  color: $primary-gray;
  text-decoration: none;
  cursor: pointer;
  padding-bottom: 28px;
}

.grabbi_spitfier_for_footer_container {
  display: flex;
  padding-top: 26px;
  padding-bottom: 20px;
}

.grabbi_copy_right_container {
  padding-top: 36px;
}

@media (max-width: $mobile-breakpoint) {
  .footer_root {
    padding: 0 32px 64px 32px;
  }

  .grabbi_icon_for_footer_container {
    display: block;
  }

  .footer_links_section_grid {
    display: grid;
    grid-gap: 20px;
    padding-top: 45px;
    grid-template-columns: repeat(2, 1fr);
  }

  .footer_links_left_column {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 140px;
  }

  .footer_links_middle_column {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-column-end: 3;
    width: 120px;
  }

  .footer_links_right_column {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 140px;
  }
}

@media (max-width: $mobile-portrait) {
  .grabbi_spitfier_for_footer {
    height: 55px;
    width: 120px;
  }
}
