@import "shared/styles/icons.scss";
@import "shared/styles/variables.scss";

.membership_chip_root {
  background-color: rgba(0,0,0,0.4);
  color: $primary-white;
  font-weight: 600;

  padding: 0 0.5rem 0 0.25rem;
  border-radius: 25px;

  display: inline-block;
  
  div {
    display: inline-block;
    vertical-align: top;
  }
}