@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.holidays_activity_root {
  $mobile-breakpoint: 960px;
  .activity_card {
    display: flex;
    justify-content: space-between;

    padding: 100px 0;
    border-bottom: 1px #444750 solid;

    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
      align-items: center;
    }
  }

  .visitor_counts_area {
    display: flex;
    flex-direction: column;
  }

  .title,
  .subtitle {
    color: $primary-white;
    font-family: LemonMilkBold;
  }

  .title {
    margin-bottom: 20px;
  }
}
