@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";


.android_checkout_button_root,
.StripeElement {
  margin-bottom: 1rem;
  width: 350px !important;
}

.android_checkout_button_root_fallback {
  margin-bottom: 1rem;

  a {
    text-decoration: underline;
  }
}

.android_checkout_button_root_loading {
  margin-bottom: 1rem;
}