@import "shared/styles/variables.scss";

$slide-count: 5;
$slide-height: 18rem;
$slide-width: 30rem;

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: inherit;
}

ul {
  list-style-type: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

.carousel__wrap {
  padding: 48px 0 96px 0;
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  width: 80%;
  left: 50%;
  transform: translateX(-50%);
}

.carousel__inner {
  position: relative;
  height: 34rem;
  width: calc(#{$slide-width * 3});
}

.carousel__container {
  height: 80%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel_header_text_container {
  text-align: center;
}

.carousel_header_text {
  color: $primary-black;
  font-family: LemonMilkBold;
  font-size: 2.25rem;
  line-height: 3rem;
  letter-spacing: normal;
  padding-bottom: 15px;
}

.carousel_body_text_container {
  padding-bottom: 16px;
  text-align: center;
}

.carousel_body_text {
  color: $primary-black;
  font-family: Roboto-Regular;
  font-size: 1.75rem;
  line-height: 3rem;
  letter-spacing: normal;
  padding-bottom: 42px;
}

.carousel__slide-list {
  height: 100%;
  left: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  width: calc(#{($slide-count + 0.5) * $slide-width * 2});
}

.carousel__slide-item {
  display: inline-block;
  margin: 0;
  padding: 1rem;
  position: absolute;
  transition: all 0.3s;
  height: $slide-height;
  width: $slide-width;
}

.item_sub_header_text {
  color: $primary-black;
  font-family: Roboto-Bold;
  font-size: 1.5rem;
  line-height: 3rem;
  letter-spacing: normal;
  padding-bottom: 12px;
}

.item_sub_description_text {
  color: $primary-black;
  font-family: Roboto-Regular;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  padding-bottom: 12px;
}

.carousel__slide-item-img-link {
  cursor: pointer;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  img {
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease;
    width: 100%;
  }

  &::before {
    content: "";
    position: absolute;
    left: 41.75%;
    top: 35%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 75px;
    height: 75px;
    background: $primary-yellow;
    border-radius: 50%;
    animation: pulse-purple 1500ms ease-out infinite;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: $primary-purple;
    border-radius: 50%;
    transition: all 200ms;
    background-image: url("/assets/grabbi-player-purpule-button.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &:hover {
    &::after {
      opacity: 1;
      background-color: darken($primary-purple, 10%);
      background-image: url("/assets/grabbi-player-purpule-button.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    img {
      transform: scale(1.3);
    }
  }
}

@keyframes pulse-purple {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(142, 68, 173, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0);
  }
}

.carousel-slide-item__body {
  bottom: -2.5rem;
  height: 10%;
  padding-top: 16px;

  h4 {
    margin: 0.7rem 0 0;
    text-transform: uppercase;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.3;
    margin: .7rem 1rem 0 0;
  }
}

.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &--prev {
    left: -4rem;
  }

  &--next {
    right: -4rem;
  }
}

.carousel__btn-arrow {
  border: solid black;
  border-width: 0 0.4rem 0.4rem 0;
  padding: 3px;
  height: 1.5rem;
  width: 1.5rem;
  z-index: 10;

  &--left {
    transform: rotate(135deg);
  }

  &--right {
    transform: rotate(-45deg);
  }
}

.carousel__dots {
  display: inline-block;
  left: 50%;
  margin-top: 2rem;
  position: absolute;
  transform: translateX(-50%);

  .dot {
    background: #ccc;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: 2rem;
    margin: 0 0.3rem;
    outline: none;
    transform: scale(0.5);
    width: 2rem;

    &.active {
        background: black;
    }
  }
}

.video_container {
  inline-size: inherit;
  // width: 528px;
  // height: 304px;
  width: 480px;
  height: 250px;
  box-sizing: border-box;
}

@media (max-width: $ipad-breakpoint) {
  .carousel__container {
    height: 61%;
  }

  .carousel__inner {
    height: 45rem;
  }

  .carousel__wrap {
    padding: 48px 0 0 0;
  }
}

@media (max-width: $mobile-breakpoint) {
  .carousel_header_text {
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
  }

  .carousel_body_text {
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .carousel__inner {
    width: calc(#{$slide-width * 1});
    height: 45rem;
  }

  .carousel__wrap {
    padding: 48px 0 0 0;
  }

  .carousel__container {
    height: 61%;
  }

  .carousel__btn {
    &--prev {
      left: -2rem;
    }

    &--next {
      right: -2rem;
    }
  }

  .carousel-slide-item__body {
    display: block;
    width: 320px;
    margin: 0 auto;
    padding: 0 10px;
  }

  .carousel__dots {
    width: 220px;
  }

  .grabbi_signup_video {
    width: 315px;
    height: 184px;
  }
}
