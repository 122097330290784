@import "shared/styles/variables.scss";

.edit_merchandise_dialog_root {
  display: flex;
  flex-direction: column;

  .grabbi_dialog_content {
    padding: 30px;

    .title {
      margin: 0 auto;
      padding-bottom: 30px;
    }

    .delete_merch_name {
      font-family: LemonMilkBold;
      margin-bottom: 30px;
    }

    .warning {
      font-family: Roboto-Black;
    }

    .form_area {
      .field {
        width: 400px;
        max-width: calc(100% - 30px);
      }
      margin: 0 auto;
    }

    .action_area {
      margin-top: 30px;
      display: flex;
      justify-content: center;

      .grabbi_button_root {
        margin-right: 30px;
      }

      .grabbi_button_root:nth-child(2) {
        background-color: unset;
        color: $primary-black;
        border: 3px solid $primary-black;
      }
    }

    .loading {
      justify-content: center;
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .grabbi_dialog_content {
      .form_area{
        .field {
          width: 320px;
          max-width: calc(100% - 30px);
        }
      }
    }

    .grabbi_dialog_content {
      padding: 5px;
    }
  }
}
