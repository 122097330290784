@import "shared/styles/variables.scss";

.grabbi_text_field_root_black {

  .text_field_input,
  .text_field_small_input,
  .text_field_large_input,
  .text_field_input:hover,
  .text_field_focused,
  .text_field_label,
  .text_field_label:focus {
    border-color: $primary-white  !important;
    background-color: $primary-black  !important;
    color: $primary-white  !important;

    &::placeholder {
      color: $primary-white  !important;
    }
  }
}

.grabbi_text_field_root_yellow {

  .text_field_input,
  .text_field_small_input,
  .text_field_large_input,
  .text_field_input:hover,
  .text_field_focused,
  .text_field_label,
  .text_field_label:focus {
    background-color: $primary-yellow;
    color: $primary-black;

    &::placeholder {
      color: $primary-black;
    }
  }
}

.text_field_root {
  margin-bottom: 24px;
  width: 336px;
  max-width: 100%;

  .MuiTextField-root {
    width: 100%;
  }
}

.text_field_root_small {
  margin-bottom: 15px;
  width: 280px;
  align-self: flex-end;
}

.text_field_root_large {
  margin-bottom: 15px;
  width: 575px;
}

.text_field_input {
  font-family: Roboto-Black;
  font-size: 16px;
  letter-spacing: normal;
  border-radius: 4px;
  width: 336px;
  max-width: 100%;
  border: 2px solid;

  &::placeholder {
    opacity: 1;
  }
}

.text_field_small_input {
  font-family: Roboto-Black;
  font-size: 16px;
  letter-spacing: normal;
  border-radius: 4px;
  width: 280px;
  border: 2px solid;

  &::placeholder {
    opacity: 1;
  }
}

.text_field_large_input {
  font-family: Roboto-Black;
  font-size: 16px;
  letter-spacing: normal;
  border-radius: 4px;
  width: 575px;
  border: 2px solid;

  &::placeholder {
    opacity: 1;
  }
}

.text_field_label_disabled {
  opacity: 0.7;
}

.text_field_disabled {
  opacity: 0.7;
}

.text_field_disabled:hover {
  opacity: 0.7 !important;
}

.text_field_error {
  font-family: LemonMilkBold;
  color: $primary-purple;
  font-weight: 600;
  font-size: 14px;
  text-align: left;
}

.text_field_label {
  font-family: Roboto-Black;
  font-size: 16px;
  letter-spacing: normal;
}

.text_field_label_focused {
  font-size: 14px !important;
  background-color: rgba($primary-yellow, 0.7);
  opacity: 0.7;
}

.MuiFilledInput-underline:before,
.MuiFilledInput-underline:hover:before,
.MuiFilledInput-underline:after,
.MuiFilledInput-underline.Mui-error:after {
  border-bottom: unset;
}

::-webkit-input-placeholder {
  opacity: 1;
}

:-moz-placeholder {
  /* Firefox 18- */
  opacity: 1;
}

::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 1;
}

:-ms-input-placeholder {
  opacity: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $primary-black;
  -webkit-box-shadow: 0 0 0px 1000px $primary-yellow inset;
  transition: background-color 5000s ease-in-out 0s;
}

.MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(12px, 10px) scale(0.8);
}

.MuiFormLabel-root.Mui-error {
  color: $primary-black;
}