@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.report_dashboard_root {
  .date_options {
    margin-top: 30px;
    display: flex;
    align-items: center;

    .date_dropdown {
      width: 300px;
    }

    .grabbi_dropdown_wrapper {
      margin-right: 30px;
    }

  }

  .no_results_root {
    margin-top: 30px;
  }

  .text {
    font-size: 16px;
    font-family: LemonMilk;
  }

  .price_column, .status_column {
    vertical-align: top;
  }
}

.report_tooltip_popper {
  
  .tooltip_body {
    background-color: $primary-black;
    font-family: SF-Pro-Display-Light;
    font-size: 16px;
    text-align: center;
  }
}