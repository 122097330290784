@import "shared/styles/variables.scss";

.getstarted_wrapper {
  background-color: $primary-white;
  .grabbi_getstarted_section {
    @include sections_position;
    padding-top: 80px;
  }

  .grabbi_two_column_grid_in_getstarted {
    @include sections_width_for_website;
    position: relative;
    z-index: 2;
    display: grid;
    gap: 0px 48px;
    box-sizing: content-box;
    grid-template-columns: 43rem 26rem;
    padding: 0px 80px 48px 80px;
  }

  .grabbi_getstarted_main_header_container {
    padding-bottom: 32px;
    text-align: left;
  }

  .grabbi_getstarted_main_body_container {
    text-align: left;
  }

  .grabbi_getstarted_main_header_text {
    font-family: 'LemonMilkBold';
    color: $primary-black;
    font-size: 3.75rem;
    line-height: 4.25rem;
  }

  .grabbi_getstarted_sub_header_text {
    font-family: 'LemonMilk';
    font-size: 1.25rem;
    line-height: 2rem;
  }

  .grabbi_getstarted_button {
    background-color: $primary-purple;
    color: $primary-white;
    font-family: LemonMilkBold;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: normal;
    height: 56px;
    border-radius: 4px;
    width: 100%;
  }

  .grabbi_getstarted_button:disabled {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.16);
    border: 4px solid rgba(0, 0, 0, 0.16);
  }

  .grabbi_getstarted_form_texboxes {
    justify-content: center;
    padding: 0;
    box-sizing: content-box;
  }

  .grabbi_texboxes_with_light_background {
    background-color: $primary-white;
    border-color: $primary-gray;
    color: $primary-black;
    width: 416px;
  }

  .grabbi_texbox_label_with_light_background {
    background-color: $primary-white;
    font-family: Roboto-Black;
    font-size: 16px;
    letter-spacing: normal;
    color: $primary-black;
  }

  .grabbi_label_with_light_background_focused {
    background-color: $primary-white;
    border-color: $primary-purple;
  }

  .grabbi_texbox_label_with_light_background:focus {
    font-size: 14px;
  }

  .grabbi_texbox_label_with_light_background_focused {
    color: $primary-black;
    background-color: $primary-white;
    font-size: 14px;
  }

  .tgrabbi_texbox_label_with_light_background_disabled {
    opacity: 0.7;
  }

  .grabbi_three_column_grid_in_starter_kit {
    @include sections_width_for_website;
    position: relative;
    z-index: 2;
    display: grid;
    gap: 0px 10px;
    justify-content: center;
    box-sizing: content-box;
    grid-template-columns: repeat(3,1fr);
    padding: 48px 80px;
  }

  .getstarted_form {
    width: 100%;
  }

  .grabbi_getstarted_section_sub_header_text_container {
    text-align: center;
    padding: 30px 14px 0px 14px;
  }

  .grabbi_getstarted_section_sub_header_text {
    color: $primary-white;
    font-family: Roboto-Bold;
    font-size: 1.5rem;
    line-height: 3rem;
    letter-spacing: normal;
    padding-bottom: 12px;
  }

  .link_to_email {
    color: $primary-purple;
    text-decoration: underline;
  }

 .grabbi_grabbi_getstarted_partners_testimonials_header_container {
    text-align: center;
    padding: 0;
  }

  .grabbi_getstarted_partners_testimonials_header_text {
    @include grabbi_getstarted_offer_section_header_text;
    color: $primary-black;
  }

  .grabbi_getstarted_partners_testimonials_body_text_container {
    text-align: center;
    padding: 30px 24px;
  }

  .grabbi_getstarted_partners_testimonials_body_text {
    @include grabbi_getstarted_offer_section_body_text;
    color: $primary-black;
  }

  .grabbi_partner_testimonials_image {
    height: 263px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .grabbi_partners_logo_header_text_container {
    padding-top: 48px;
    text-align: center;
  }

  .grabbi_partners_logo_header_text {
    @include grabbi_partners_section_header_text;
  }

  .grabbi_partners_section_grid {
    @include sections_width_for_website;
    position: relative;
    z-index: 2;
    display: grid;
    gap: 24px 24px;
    justify-content: center;
    box-sizing: content-box;
    grid-template-columns: repeat(6,1fr);
    padding: 48px 80px;
  }

  .grabbi_partners_logo {
    height: 172px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  .ready_to_start_section_in_starter_kit_background_container {
    @include sections_background_position;
    background-color: $primary-white;
    height: 450px;
  }

  .MuiFilledInput-input:-webkit-autofill,
  .MuiFilledInput-input:-webkit-autofill:hover,
  .MuiFilledInput-input:-webkit-autofill:focus {
    -webkit-text-fill-color: $primary-black !important;
    -webkit-box-shadow: 0 0 0px 1000px $primary-white inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
  }

  @media screen and (max-width: $desktop-max-width-for-narrow-content){
    .grabbi_getstarted_main_header_text {
      font-size: 3rem;
      line-height: 3.5rem;
    }

    .grabbi_getstarted_sub_header_text {
      font-size: 1rem;
    }

    .grabbi_two_column_grid_in_getstarted {
      gap: 0px 32px;
      grid-template-columns: 43rem 20rem;
    }

    .grabbi_texboxes_with_light_background {
      width: 320px;
    }
  }

  @media screen and (max-width: $tablet-breakpoint){
    .grabbi_two_column_grid_in_getstarted {
      gap: 0px 24px;
      grid-template-columns: 32rem 20rem;
    }

    .grabbi_getstarted_partners_testimonials_header_text {
      font-size: 2.75rem;
      line-height: 2.5rem;
    }

    .grabbi_getstarted_partners_testimonials_body_text {
      font-size: 1.5rem;
      line-height: 2.75rem;
    }
  }

  @media screen and (max-width: $ipad-breakpoint){
    .grabbi_getstarted_section {
      padding-top: 24px;
    }

    .grabbi_two_column_grid_in_getstarted {
      grid-template-columns: 600px;
      padding: 0px 20px 48px 20px;
      justify-content: center;
    }

    .grabbi_getstarted_form {
      justify-content: center;
      display: grid;
    }

    .grabbi_getstarted_main_header_container {
      text-align: center;
    }

    .grabbi_getstarted_main_body_container,
    .grabbi_partners_logo_header_text_container {
      text-align: center;
    }

    .getstarted_form {
      margin-top: 24px;
    }

    .grabbi_texboxes_with_light_background,
    .grabbi_getstarted_button,
    .demo_text_field_root_large {
      width: 280px;
    }

    .grabbi_three_column_grid_in_starter_kit {
      gap: 0px 10px;
      grid-template-columns: 310px;
      padding: 48px 20px;
    }

    .grabbi_grabbi_getstarted_partners_testimonials_header_container {
      text-align: center;
    }

    .getstarted_wrapper .grabbi_partners_logo_header_text {
      font-size: 2rem;
      line-height: 2.75rem;
    }

    .grabbi_partners_section_grid {
      grid-template-columns: repeat(3, 1fr);
      padding: 24px 20px;
    }

    .grabbi_partners_logo_header_text {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }

  @media screen and (max-width: $mobile-breakpoint) {
    .grabbi_two_column_grid_in_getstarted {
      grid-template-columns: 400px;
    }
  }

  @media screen and (max-width: $mobile-portrait){
    .grabbi_getstarted_section {
      padding-top: 24px;
    }

    .grabbi_two_column_grid_in_getstarted {
      grid-template-columns: 280px;
    }

    .grabbi_three_column_grid_in_starter_kit {
      gap: 0px 10px;
      grid-template-columns: 310px;
      padding: 48px 20px 18px 20px;
    }

    .grabbi_grabbi_getstarted_partners_testimonials_header_container {
      text-align: center;
    }

    .grabbi_partners_section_grid {
      grid-template-columns: repeat(3, 1fr);
      padding: 24px 20px;
    }

  }
}
