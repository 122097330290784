@import "shared/styles/variables.scss";

.add_store_wrapper {
  .show_add_store_details {
    display: '';
  }

  .add_store_error {
    font-family: LemonMilkBold;
    font-size: 16px;
    line-height: 18.75px;
    color: $primary-purple;
    text-align: left;
  }

  .hide_add_store_details {
    display: none;
  }

  .add_store_detail_grid {
    @include sections_position;
    max-width: 855px;
    margin: 0 auto;
    display: grid;
    grid-gap: 12px;
    justify-content: center;
    box-sizing: content-box;
  }

  .add_store_detail_select_region_grid {
    @include sections_position;
    max-width: 855px;
    margin: 0 auto;
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    padding: 0;
    box-sizing: content-box;
  }

  .add_store_region {
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .add_store_details {
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .add_store_details_select_first_region {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .add_store_details_select_second_region {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .region-usa-ca {
    height: 132px;
    width: 162px;
    background-repeat: no-repeat;
    cursor: pointer;
    padding-bottom: 10px;
  }

  .region-ca-on-toronto {
    height: 132px;
    width: 162px;
    background-repeat: no-repeat;
    cursor: pointer;
    padding-bottom: 10px;
  }

  .add_store_root {
    display: flex;
    flex-direction: column;
    width: 336px;
    margin: 0 auto;
    text-align: center;
  }

  .add_store_title_container {
    display: flex;
    flex-direction: column;
    width: 336px;
    margin: 0 auto;
    text-align: center;
    padding-top: 128px;
  }

  .add_store_title {
    font-family: LemonMilkBold;
    font-size: 3.25rem;
    line-height: 3.87rem;
    letter-spacing: normal;
    margin-bottom: 60px;
  }

  .add_store_detail_sub_title,
  .add_store_region_sub_title {
    font-family: LemonMilkBold;
    font-size: 1.5rem;
    line-height: 2.25rem;
    letter-spacing: normal;
    text-align: left;
  }

  .add_store_detail_sub_title {
    margin-bottom: 24px;
  }

  .add_store_region_sub_title {
    margin-bottom: 3px;
  }

  .select_region_sub_title {
    font-family: Roboto-Regular;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 22px;
    text-align: left;
  }

  .region_name_sub_title {
    font-family: LemonMilkBold;
    font-size: 1rem;
    line-height: 1.18rem;
    letter-spacing: normal;
    margin-bottom: 24px;
  }

  .add_store_sub_title {
    font-family: LemonMilkBold;
    font-size: 1rem;
    line-height: 1.12rem;
    letter-spacing: normal;
    margin-bottom: 51px;
  }

  .add_store_error_message {
    height: 33px;
    margin-bottom: 15px;
    text-align: center;
  }

  .add_store_text_field {
    height: 56px;
    width: 100%;
  }

  .add_store_form {
    width: 100%;
  }

  .add_store_form_group_root {
    margin-bottom: 20px;
  }

  .add_store_form_helper_text_root {
    font-family: LemonMilkBold;
    color: $primary-purple;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: normal;
  }

  .add_store_reset_password_link {
    cursor: pointer;
    font-family: LemonMilkBold;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: normal;
    color: $primary-black;
    text-decoration: underline;
    font-weight: 700;
    margin-left: 20px;
    margin-bottom: 25px;
    align-self: flex-start;
  }

  .add_store_subtext_link {
    color: $primary-black;
    font-family: Roboto-Regular;
    text-decoration: underline;
    font-weight: 600;
  }

  .add_store_subtext {
    font-family: Roboto-Regular;
    margin-bottom: 27px;
    text-align: left;
  }

  .store_state_name_container {
    margin: 4px 0 6px 0;
  }
  .store_state_name_text {
    font-family: Roboto-Regular;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
  }

  .add_store_container {
    display: flex;
    flex-direction: column;
    width: 778px;
    margin: 0 auto;
    text-align: center;
    padding-top: 67px;
  }

  .add_store_submit_button {
    background-color: $primary-purple;
    color: $primary-white;
    font-family: LemonMilkBold;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: normal;
    height: 56px;
    border-radius: 4px;
    width: 336px;
    margin: 0 auto;
  }

  .grabbi_country_state_drop_down {
    background-color: $primary-yellow;
    font-family: Roboto-Black;
    font-size: 16px;
    letter-spacing: normal;
    color: $primary-black;
    border-radius: 4px;
    width: 336px;
    border: 2px solid $primary-black;
    &::placeholder {
      color: $primary-black;
      opacity: 1;
    }
    padding: 20px 12px 20px 10px;
    margin-bottom: 24px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("/assets/dp-arrow-16x16.svg") no-repeat;
    background-position: right center;
    position: relative;
    background-size: 32px;
  }
}
