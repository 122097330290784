@import "shared/styles/variables.scss";

$desktop-width: 495px;
$mobile-width: 380px;

.edit_profile_printer_setup_content {
  .loading {
    color: $primary-white;
  }

  .setup_printer_header_text {
    float: left;
    text-align: left;
    display: flex;
    width: $desktop-width;
    @include body_setup_printer_text;
  }

  .setup_printer_header_section_divider {
    display: block;
    width: $desktop-width;
    font-size: 1px;
    line-height: 2px;
    background-color: $primary-black;
    margin: 0 auto;
  }

  .setup_printer_row_section_divider {
    display: block;
    width: $desktop-width;
    font-size: 1px;
    line-height: 2px;
    background-color: $primary-dark-gray;
    margin: 0 auto;
    opacity: .2;
  }

  .columns {
    margin: 22px 0px;
    display: flex;
    width: $desktop-width;
  }

  .left_column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;
    margin-right: 80px;
    text-align: center;
    @include body_setup_printer_text;
  }

  .right_column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 60%;
  }

  .save_action_button {
    margin-top: 30px;
    width: 200px;
  }

  @media (max-width: $mobile-breakpoint) {
    .setup_printer_header_text {
      width: $mobile-width;
    }

    .columns {
      display: inline-table;
      width: $mobile-width;
    }

    .setup_printer_header_section_divider {
      width: $mobile-width;
    }

    .left_column {
      @include body_setup_printer_text;
    }

    .right_column {
      width: 100%;
    }
  }
}