@import "shared/styles/variables.scss";

.routes_root {
  min-height: 100vh;
}

.home_padded {
  padding-left: 80px;
  padding-right: 80px;
  @media (max-width: $mobile-breakpoint) {
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }
}

.home_header {
  flex: 0 1 80px;
}

.home_routes {
  flex: 1 1;
  align-self: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $primary-yellow;
}

.home_footer {
  flex: 0 1;
}

.home_footer_padding {
  padding-top: 100px;
}
