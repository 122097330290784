@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.login_wrapper {
  .login_root {
    display: flex;
    flex-direction: column;
    width: 336px;
    margin: 0 auto;
    padding-top: 128px;
    padding-bottom: 50px;
  }

  .to_continue {
    margin-top: 50px;
    text-align: center;
  }

  .login_title {
    font-family: LemonMilkBold;
    font-size: 3.25rem;
    line-height: 3.87rem;
    letter-spacing: normal;
    margin-bottom: 15px;
    text-align: center;
  }

  .login_error_message {
    height: 33px;
    margin-bottom: 15px;
    text-align: center;
  }

  .login_text_field {
    height: 56px;
    width: 100%;
  }

  .login_form {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login_form_group_root {
    margin-bottom: 20px;
  }

  .login_form_helper_text_root {
    font-family: LemonMilkBold;
    color: $primary-purple;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: normal;
    margin-bottom: 10px;
    align-self: flex-start;
  }

  .login_forgot_password_link {
    cursor: pointer;
    font-family: Roboto-Black;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    color: $primary-black;
    margin-bottom: 25px;
    text-decoration: none;
    align-self: flex-start;
  }

  .login_subtext_link {
    color: $primary-black;
    font-family: Roboto-Regular;
    text-decoration: underline;
    font-weight: 600;
  }

  .login_subtext {
    font-family: Roboto-Regular;
    text-align: left;
  }

  .login_submit_button {
    background-color: $primary-purple;
    color: $primary-white;
    font-family: LemonMilkBold;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: normal;
    height: 56px;
    border-radius: 4px;
    width: 100%;
    margin-top: 25px;
  }

  .login_field_error {
    color: red;
    font-weight: 600;
  }

  .seperator {
    display: flex;
    align-items: center;
    div {
      background-color: #000000;
      height: 2px;
      width: 45%;
    }
    p {
      padding: 0 10px;
      font-family: LemonMilk;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
    }
  }

  .no_account {
    margin-top: 40px;
    text-align: center;
  }

  .signup_button {
    margin-top: 15px;
  }

  .social_sign_in {
    padding-top: 20px;
    width: 100%;

    .social_button {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      border-radius: 50px !important;
    }

    .google_sign_in {
      width: 100%;
      height: 50px;
      opacity: 1 !important;
      background-color: #ffffff !important;
      color: #000000 !important;
      border: 2px solid $primary-black !important;
      span {
        font-family: LemonMilkBold;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 2px;
      }
    }

    .apple_sign_in {
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
        rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
      border-radius: 2px;
      background-color: #000000;
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      .apple_icon {
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        margin-right: 15px;
      }
      .text {
        font-size: 12px;
        color: #ffffff;
        font-family: LemonMilkBold;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 2px;
      }
    }
    .apple_sign_in:active,
    .apple_sign_in:focus {
      border: 1px black solid;
    }
  }
}
