@import "shared/styles/variables.scss";

.grabbi_tabs_root {
  padding-top: 30px;
  border-bottom: thick solid;
  border-bottom-width: 6px;
  border-bottom-color: $primary-black;
}

.grabbi_tabs_tab {
  font-family: LemonMilk;
  padding: 0 16px;
}

.grabbi_tabs_tab_selected {
  background-color: $primary-black;
  color: $primary-white;
}

.grabbi_tabs_tab_indicator {
  display: none;
}

.grabbi_tabs_filter_dialog_title {
  font-family: LemonMilkBold;
  font-size: 1.75rem;
  line-height: 37px;
  letter-spacing: 0.02px;
}

.grabbi_tabs_filter_dialog_radio_group {
  margin-top: 10px;
  margin-bottom: 10px;
}

.grabbi_tabs_filter_dialog_button {
  cursor: pointer;
  margin-top: 20px;
  width: 100%;
  background-color: $primary-purple;
  font-family: LemonMilk;
  color: $primary-white;
  padding: 16px 0;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01px;
  border: unset;
  border-radius: 5px;
}

.grabbi_tabs_filter_dialog_radio_label_root {
  margin-top: 8px;
  margin-bottom: 8px;
}

.grabbi_tabs_filter_dialog_radio_label {
  font-family: LemonMilk;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01px;
}

.grabbi_tabs_filter_dialog_top {
  display: flex;
  justify-content: flex-end;
}

.grabbi_tabs_filter_dialog_top_mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary-yellow;
  font-family: LemonMilkBold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 1.23px;
  width: 100%;
  border: thick solid;
  border-color: $primary-black;
  border-width: 4px;
  padding-left: 18px;
  padding-right: 7.5px;
  height: 56px;
  border-radius: 4px;
  cursor: pointer;
}