@import "shared/styles/variables.scss";

.updating_row_root {
  .loading_wrapper {
    height: 39.33px;
    border-bottom: 2px solid $primary-black;
    background-color: $primary-black;
    color: $primary-white;
    text-align: center;
    font-family: LemonMilkBold;
    font-size: 20px;

    .flex_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .loading {
      margin-left: 20px;
      height: 39px !important;
      width: 39px !important;
      color: $primary-white;
    }
  }
}