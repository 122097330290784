@import "shared/styles/variables.scss";

.grabbi_button_root {
  font-family: LemonMilkBold;
  font-size: 16px;
  letter-spacing: normal;
  height: 3rem;
}

.grabbi_button_root_rectangle {
  border-radius: 4px;
}

.grabbi_button_root_rounded {
  border-radius: 25px;
}

.grabbi_button_root_black {
  background-color: $primary-black;
  color: $primary-white !important;

  &:hover {
    opacity: 0.92;
    background-color: $primary-black;
  }
}

.grabbi_button_root_purple {
  background-color: $primary-purple;
  color: $primary-white;

  &:hover {
    opacity: 0.92;
    background-color: $primary-purple;
  }
}

.grabbi_button_root_blue {
  background-color: $primary-blue;
  color: $primary-white;

  &:hover {
    opacity: 0.92;
  }
}

.grabbi_button_root_white {
  background-color: #EEEEEE;
  color: $primary-black;

  &:hover {
    opacity: 0.92;
  }
}

.grabbi_button_root_gray {
  background-color: #626262;
  color: $primary-black;

  &:hover {
    opacity: 0.92;
  }
}

.grabbi_button_root_yellow {
  background-color: $primary-yellow;
  color: $primary-black;

  &:hover {
    opacity: 0.92;
  }
}

.grabbi_button_root_outlined {
  border: 2px solid $primary-black;
  font-weight: 600;
  color: $primary-black;
}

.grabbi_button_disabled {
  opacity: 0.7;
}

.grabbi_button_no_radius {
  border-radius: 0px;
}