@import "shared/styles/variables.scss";

.navigation_mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;
  height: 62px;
  background-color: $primary-black;
}

.nav_menu_icon_mobile {
  color: $primary-white;
}

.nav_menu_header_mobile {
  color: $primary-white;
  font-size: 22px;
  font-family: LemonMilkBold;
}

.nav_item {
  height: 100px;
}

.nav_item_selected {
  height: 100px;
  background-color: $primary-purple;
}

.nav_item_text {
  width: 100%;
  font-size: 16px;
  letter-spacing: 1.23px;
  line-height: 23px;
  font-family: LemonMilkLight;
  color: #ffffff;
  text-align: center;
}

.nav_drawer {
  background-color: $primary-black;
  width: 100px;
  color: $primary-white;
}

.nav_drawer_paper {
  overflow-y: hidden;
}

.nav_drawer_area {
  background-color: $primary-black;
  min-height: calc(100vh - 100px);
}

.nav_drawer_padding {
  padding-top: 0;
  padding-bottom: 0;
}

.nav_user_icon_box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  background-color: $primary-black;
}

.nav_user_icon {
  font-family: LemonMilkBold;
  font-size: 20px;
  letter-spacing: 1.54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-yellow;
  color: $primary-black;
  height: 50px;
  width: 50px;
  border-radius: 25px;
}

.nav_user_icon_mobile {
  height: 36px;
  width: 36px;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 1.15px;
}
