@import "shared/styles/variables.scss";

.change_password_forgot_root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  justify-self: center;

  .content {
    .title {
      font-family: LemonMilkBold;
      font-size: 52px;
      line-height: 62px;
      margin-bottom: 30px;
    }

    .field {
      width: 350px;
      max-width: 100%;
    }

    .form_error {
      font-family: LemonMilkBold;
      color: $primary-purple;
      font-weight: 600;
      font-size: 16px;
      text-align: left;
    }

    .button {
      width: 100%;
    }
  }
}
