@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.change_tip_button_root {
  
}

.change_tip_dialog_footer_root {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;

  .grabbi_button_root {
    width: 300px;
  }
}
