@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.order_code_root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .order_number_container {
    display: flex;
    align-items: center;
    .order_color_circle {
      border-radius: 25px;
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.4rem;
    }
    .order_number {
      font-family: LemonMilk;
      @include large-text();
    }
  }

  .ordered_on_container {
    font-weight: 500;
    margin-left: 1.2rem;
    text-align: center;
  }
}
