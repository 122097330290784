@import "shared/styles/variables.scss";

.grabbi_more_actions_root {
  display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    padding-left: 1rem;
    padding-right: 1rem; 
    background-color: $primary-white;
    cursor: pointer;
}

.grabbi_more_actions_menu_wrapper_root {
    background-color: $primary-yellow;
    border: 0.25rem solid $primary-black;
    width: 20rem;
    
    .menu {
      padding: 0;
      
        li {
          display: flex;
          font-family: LemonMilk;
          font-size: 1rem;
          cursor: pointer;
          height: 2.75rem;
          padding: 1.75rem 1.25rem;
          border-bottom: 1px solid $primary-black;

          &:last-child {
            border-bottom: unset;
          }
        }
    }
  }