@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.edit_merchandise_root {
  @include dashboard_content;

  .merchandise_form_root {
    margin-top: 30px;
  }

  .toppings_menu {
    margin-bottom: 30px;
    .add_menu {
      display: flex;
      align-items: center;

      .dropdown {
        width: 300px;
      }
    }

    .add_menu > * {
      margin-right: 15px;
    }

    .grabbi_button_root {
      height: 50px;
      width: 200px;
    }
  }

  table {
    margin-bottom: 50px;
    .MuiTableRow-root {
      th {
        .checkmark_header {
          height: 24px;
          width: 24px;
          margin-left: 13px;
          background-image: url(/assets/checkmark.svg);
          background-repeat: no-repeat;
        }
      }
      td {
        font-size: 22px;
        svg {
          width: 30px;
          height: 30px;
          color: $primary-black;
        }
        .loading {
          svg {
            color: $primary-white;
          }
        }
      }
    }
  }

  .refresh_state {
    opacity: 0.4;
  }

  .loading_icon_area {
    margin-top: 30px;
  }

  .no_results_root {
    padding-top: 0px;
  }
}
