@import "shared/styles/variables.scss";

.edit_profile_error {
  color: red;
  font-weight: 600;
}

.edit_profile_root {
  @include sections_width;
  max-width: 1280px;
  @include sections_padding_mobile;
}

.edit_profile_header {
  font-family: LemonMilkBold;
  font-size: 36px;
  line-height: 62px;
  letter-spacing: normal;
}

.edit_profile_button {
  margin-top: 60px;
  background-color: $primary-purple;
  color: $primary-white;
  font-family: LemonMilkBold;
  font-size: 16px;
  line-height: 56px;
  letter-spacing: normal;
  height: 56px;
  border-radius: 4px;
  width: 100%;
  word-wrap: none;
}

.edit_profile_content {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-bottom: 40px;
}

.edit_profile_form_error {
  font-family: LemonMilkBold;
  color: $primary-purple;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
}

.edit_profile_loading_icon_wrapper {
  position: relative;
  height: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.edit_profile_personal_details_content {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: $mobile-breakpoint) {
    width: 100%;
  }
}

.edit_profile_button_personal_details {
  width: 100%;
  @media (max-width: $mobile-breakpoint) {
    width: 360px;
  }
}

.edit_profile_change_password_content {
  max-width: 360px;
}

.edit_profile_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

.edit_profile_store_details_content {
  display: flex;
  justify-content: center;
  @media (max-width: $mobile-breakpoint) {
    flex-direction: column;
    align-items: center;
  }

  .grabbi_dropdown_wrapper {
    width: 336px;
  }
}

.edit_profile_store_details_primary_store {
  padding-left: 60px;
  width: 33%;
  @media (max-width: $mobile-breakpoint) {
    margin-bottom: 50px;
    width: 100%;
  }
}

$column-direction-breakpoint: 1130px;

.edit_profile_store_details_right_section {
  width: 66%;
  display: flex;
  flex-direction: column;
  @media (max-width: $column-direction-breakpoint) {
    align-items: center;
  }
  @media (max-width: $mobile-breakpoint) {
    width: 100%;
  }
}

.edit_profile_store_details_region_section {
  width: 50%;
  @media (max-width: $column-direction-breakpoint) {
    text-align: center;
    width: 350px;
  }
}

.edit_profile_store_details_region_area {
  display: flex;
  justify-content: flex-start;
  width: 100%px;
  margin-top: 10px;
}

.edit_profile_store_details_region_text_box {
  border-bottom: 5px solid $primary-black;
  width: 350px;
  padding-bottom: 8px;
}

.edit_profile_store_details_region_text {
  font-family: LemonMilkBold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 1.2px;
}

.edit_profile_store_details_columns {
  margin-top: 30px;
  display: flex;
  @media (max-width: $column-direction-breakpoint) {
    flex-direction: column;
    align-items: center;
  }
}

.edit_profile_store_details_left_column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  @media (max-width: $column-direction-breakpoint) {
    align-items: center;
    width: 100%;
  }
}

.edit_profile_store_details_right_column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
  @media (max-width: $column-direction-breakpoint) {
    align-items: center;
    width: 100%;
  }
}

.edit_profile_update_profile_button {
  width: 100%;
  height: 50px;
  @media (max-width: $column-direction-breakpoint) {
    width: 350px;
  }
}

.edit_profile_store_details_header_1 {
  font-family: LemonMilkBold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: normal;
}

.edit_profile_store_details_header_2 {
  font-family: LemonMilkBold;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: normal;
}

.edit_profile_store_details_form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: $desktop-max-width-for-content) {
    align-items: flex-start;
  }
  @media (max-width: $mobile-breakpoint) {
    align-items: center;
  }
}

.edit_profile_store_details_subtext {
  font-family: LemonMilkLight;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: normal;
}

.edit_profile_store_details_add_store_button {
  background-color: #01a3ee !important;
  width: 203px;
}

.edit_profile_store_details_radio_group {
  @media (min-width: $mobile-breakpoint + 1) {
    max-width: 80%;
  }
  margin-top: 12px;
}

.edit_profile_store_details_radio_label {
  margin-left: 10px;
}

.edit_profile_store_details_radio_label_subtext {
  margin-left: 40px;
}

.edit_profile_field {
  width: 350px;
  max-width: 100%;
}

.edit_store_dropdown_menu {
  margin-bottom: 24px;
  height: 60px;
}



.edit_profile_store_details_header {
  @media (min-width: $mobile-breakpoint + 1) {
    width: 350px;
    text-align: start;
  }
}

.edit_profile_field_store_details {
  font-family: Roboto-Black;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: normal;
}
