@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.line_chart_root {
  .chart_label {
    border-radius: 5px;
    border: 1px black solid;
  }

  .custom_cursor_line {
    stroke: #2f89fc !important;
    stroke-width: 5px;
    stroke-dasharray: 5 5;
  }
}
