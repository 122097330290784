@import "shared/styles/variables.scss";
@import "shared/styles/layout.scss";
@import "shared/styles/fonts.scss";
@import "shared/styles/icons.scss";

.bold {
  font-weight: 600;
}

.clickable {
  cursor: pointer;
}

.title {
  font-family: LemonMilkBold;
  font-size: 32px;
}

.subtitle {
  font-family: LemonMilk;
  font-size: 18px;
  color: $primary-black;
}

.small_text {
  font-size: 1rem;
}

.text_icon_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon_black {
  color: $primary-black;
  height: 40px;
  width: 40px;
}

.clickable_black {
  cursor: pointer;
  transition: 300ms ease background-color;
  border-radius: 25px;
  &:hover {
    background-color: rgba($primary-black, 0.1);
  }
}

.dollar_icon {
  background-image: url("/assets/dollar-sign-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 1.4rem;
  width: 1.2rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.google_icon {
  background-image: url("/assets/google-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 20px;
  width: 20px;
}

.apple_icon {
  background-image: url("/assets/apple-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  height: 24px;
  width: 24px;
}

.no_results_root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .message {
    max-width: 425px;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1.33px;
    font-family: LemonMilk;
    color: #131313;
  }

  .header {
    padding-bottom: 14px;
    font-size: 27px;
    line-height: 38px;
    letter-spacing: normal;
    font-family: LemonMilkBold;
  }
}

.transaction_table {
  .text {
    font-family: LemonMilk;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: normal;
  }

  .checkmark_header {
    height: 24px;
    width: 24px;
    margin-left: 13px;
    background-image: url(/assets/checkmark.svg);
    background-repeat: no-repeat;
  }

  .checkbox .MuiSvgIcon-root {
    color: $primary-black;
    width: 30px;
    height: 30px;
  }

  .payment_indicator {
    font-size: 1.3rem;
    opacity: 0.7;
  }

  .table_body_cell {
    vertical-align: top;
    font-size: 28px;
  }

  .table_header_cell_mobile {
    font-size: 28px;
  }

  .table_body_cell_mobile {
    font-size: 28px;
  }

  .pop {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 120px;
    }

    .circle {
      height: 36px;
      width: 36px;
      border-radius: 18px;
      margin-right: 10px;
    }
  }

  .complete_column,
  .complete_column_mobile {
    width: 7%;
    padding-left: 10px;
    vertical-align: top;

    .complete_column_action_box {
      display: flex;
      flex-direction: column;
      align-items: center;

      .grabbi_button_root {
        height: 40px;
      }

      .grabbi-print-button {
        margin: 0;
        width: 100%;
        height: 40px;
      }

      .checkout_row {
        margin-top: 10px;
      }

      .checkout_button {
        background-color: $primary-blue;
      }

      .checkout_indicator {
        background-color: $primary-black;
        color: $primary-white;
        font-size: 1.3rem;
        padding: 5px;
        text-align: center;
      }
    }
  }

  .date_column {
    width: 15%;
  }

  .pop_cell {
    width: 10%;
  }

  .table_number_column {
    width: 10%;
  }

  .price_column {
    width: 12%;
  }

  .description_column {
    width: 58%;
  }

  .date_column_mobile {
    width: 33%;
    min-width: 60px;
  }

  .pop_column_mobile {
    width: 40%;
    min-width: 120px;
  }

  .description_column_mobile {
    min-width: 60%;
  }

  .MuiTable-root {
    border-collapse: separate !important;
  }

  .subtable_cell {
    border-bottom: 1px solid $primary-black;
    padding-bottom: 10px;
  }

  .quantity_cell {
    padding-top: 12px;
    vertical-align: top;
    text-align: center;
    width: 20px !important;
  }

  .table_number_body_cell {
    font-size: 28px;
    text-align: start;
    vertical-align: top;
  }

  .label_wrapper {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
  }

  .label_cell {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-top: 8px;
    vertical-align: top;
  }

  .time {
    font-size: 16px;
  }

  .subtable_row:last-child {
    .subtable_cell {
      border-bottom: none;
    }
  }
}

.refresh_state {
  .grabbi_tabs_root,
  .grabbi_pagination_root,
  table,
  .no_results_root {
    opacity: 0.4;
  }
}

.refresh_loading_wrapper {
  height: 0;
  .refresh_loading_icon {
    position: absolute;
    top: 45vh;
    left: 50vw;
  }
}

@mixin dashboard_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @include sections_width;
  max-width: 90%;
  @include sections_padding_mobile;
  width: 1280px;
  padding-bottom: 75px;
  @media (max-width: $tablet-breakpoint) {
    max-width: 100%;
  }
  @media (max-width: $mui-sm) {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
}
