@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.cashier_notified_root {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 3rem;

    .close_button {
      font-size: 1.4rem;
      font-family: LemonMilk;
      border-bottom: 3px solid $primary-black;
    }
  }

  .cashier_woman_icon {
    background-image: url('/assets/grabbi-cashier-woman-icon.svg');
    background-position: center;
    background-size: cover;
    height: 200px;
    width: 200px;
  }

  .title {
    font-family: unset;
    font-weight: 900;
    margin-top: 2rem;
    font-size: 2.5rem;
  }

  .order_info {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.7rem;
    padding-bottom: 0.75rem;
    border-bottom: 2px solid $primary-black;
    span {
      font-weight: 600;
    }
    .order_code {
      .order_number {
        font-size: 1.7rem;
      }
    }
  }

  .message {
    margin-top: 2rem;
    font-size: 1.3rem;
    font-weight: 600;
  }
}