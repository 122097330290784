@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.restaurant_dialog_header_container {
    flex-direction: row !important;
    flex-basis: unset;
    @include content-padding();
    border-bottom: 1px solid $primary-black;
    padding-top: 1rem;
    padding-bottom: 0.5rem;

    .title {
      font-size: 1.7rem;
      font-weight: 700;
      font-family: LemonMilkBold;
    }

    .close_button {
      font-size: 2.5rem;
    }
  }