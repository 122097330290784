@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.demo_card_root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 50px;
  $diameter: 180px;

  .icon {
    width: $diameter;
    height: $diameter;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 200px;
  }

  .text {
    margin-top: 30px;
    text-align: center;
    span {
      font-family: LemonMilk;
    }
  }
}