@mixin content-padding {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

@mixin flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin small-text {
  font-family: Roboto-Bold;
  text-transform: none;
  font-size: 1.1rem;
}

@mixin mid-text {
  font-family: LemonMilk;
  font-size: 1.1rem;
}

@mixin large-text {
  font-family: LemonMilk;
  font-size: 1.3rem;
}

@mixin white-button {
  border: unset;
  font-size: 1.2rem;
  background-color: $primary-white;
  color: $primary-black;
  font-family: Roboto-Bold;
  text-transform: none;
}
