@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

@mixin border-bottom {
  border-bottom: 1px solid $primary-black;
}

@mixin content-padding {
  padding-left: 24px;
  padding-right: 24px;
}

@mixin min-row-height {
  min-height: 80px;
}

.checkout_tab_dialog_root {
  .dialog_footer_container {
    background-color: $primary-yellow;
    border-top: 1px solid black;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    .action_button {
      height: 60px;
      width: 200px;
      margin: 0 10px;
      @include white-button();
    }
  }

  .tip_selection_container {
    display: flex;
    margin-bottom: 30px;
    .grabbi_button_root {
      height: 75px;
      width: 100%;
    }
    margin-left: 0;
    margin-right: 0;
    @media (max-width: $mui-sm) {
      padding-right: 0.3rem;
    }
  }

  .tip_amount_field {
    height: 80px;
  }

  .checkout_tab_dialog_content {
    padding-top: 15px;
    padding-left: 0;
    padding-right: 0;

    .confirmation_text_header_container {
      font: Roboto-Bold;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
    }

    .order_code_container {
      margin-bottom: 20px;
      font-size: 1.2rem;

      flex-direction: column;
      align-items: center;
      width: 100%;

      .ordered_on_container {
        margin-left: 0;
      }
    }

    .confirmation_text_info_container {
      display: flex;
      justify-content: center;
      font-family: Roboto-Regular;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 20px;
      text-align: center;
      width: 100%;

      span {
        display: flex;
        justify-content: flex-end;
        width: 50%;
        padding-right: 2rem;
      }

      strong {
        display: flex;
        justify-content: flex-start;
        width: 50%;
        padding-left: 2rem;
      }
    }

    @media (max-height: 560px) {
      .confirmation_text_info_container:last-child {
        margin-bottom: 0;
      }
    }
  }
}
