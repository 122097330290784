@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.topping_selector_root {
  font-family: LemonMilk;
  width: 100%;

  .topping_group {
    margin-top: 1rem;

    .topping_chip_container {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 1rem;
      max-width: 100%;

      .topping_chip_wrapper {
        max-width: 100%;

        .topping_price_container {

        }
      }
    }

    .topping_chip_container.required {
      flex-direction: column;
      border-top: 1.5px solid $primary-black;
      border-bottom: 1.5px solid $primary-black;

      .topping_chip_wrapper:not(:last-child) {
        border-bottom: 0.5px solid $primary-black;
      }

      .topping_chip_root {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }

    .topping_chip_container.optional {
      overflow-x: scroll;
      padding-bottom: 1rem;
      border-bottom: 1.5px solid $primary-black;

      .topping_chip_wrapper:not(:last-child) {
        .topping_chip_root {
          margin-right: 1rem;
        }
      }
    }

    .topping_chip_container:last-child {
      border-bottom: unset;
    }

    .topping_chip_container.optional::-webkit-scrollbar {
      height: 0.5rem;
    }

    .topping_chip_container.optional::-webkit-scrollbar-thumb {
      background-color: $primary-black;
      border-radius: 5px;
    }
  }

  .topping_group.invalid {
    .group_caption {
      padding: 0 2px;
      color: $primary-white;
      background-color: $primary-red;
    }
  }

  .error_message {
    color: $primary-red;
  }
}