@import "shared/styles/variables.scss";

.store_manager_root {
  width: 100%;
  padding-bottom: 50px;
}

.store_manager_loading_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-height: 400px) {
    padding-top: 15vh;
  }
  @media (min-height: 400px) {
    padding-top: 25vh;
  }
  @media (min-height: 800px) {
    padding-top: 35vh;
  }
  
  padding-bottom: auto;
}

.store_manager_scroll_status_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.store_manager_scroll_loading_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.store_manager_scroll_results {
  font-family: LemonMilk;
}

.store_manager_loading_icon {
  color: $primary-black;
}

.store_manager_close_button_mobile {
  padding: 10px 20px;
}

.store_manager_close_icon_mobile {
  color: $primary-black;
}

.store_manager_heading {
  font-size: 2.125rem;
  font-family: LemonMilkBold;
}

.store_manager_bold_text {
  font-family: LemonMilkBold;
}

.store_manager_table {
  margin-top: 18px;
}

.store_manager_tabs {
  padding-top: 30px;
  margin-bottom: 30px;
  border-bottom: thick solid;
  border-bottom-width: 6px;
  border-bottom-color: $primary-black;
}

.store_manager_tab {
  font-family: LemonMilk;
  padding: 0 16px;
}

.store_manager_tab_selected {
  background-color: $primary-black;
  color: $primary-white;
}

.store_manager_tab_indicator {
  display: none;
}

.store_manager_table_header {
  background-color: $primary-black;
  font-family: LemonMilk;
  letter-spacing: 1.08px;
  line-height: 19px;
  color: $primary-white;
}

.store_manager_table_header_mobile {
  height: 36px;
  padding-top: 0;
  padding-bottom: 0;
}

.store_manager_table_body_cell {
  font-family: LemonMilkBold;
  border-bottom-color: $primary-black;
  border-bottom-width: 2px;
}

.store_manager_table_row_expansion_panel {
  box-shadow: unset;
  background-color: $primary-yellow;
}

.store_manager_table_row_expansion_panel_summary {
  padding-left: 0;
  padding-right: 0;
}

.store_manager_table_row_expansion_panel_icon {
  position: absolute;
  top: calc(100% - 25px);
}

.store_manager_table_row_expansion_details {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
}

.store_manager_table_body_field_mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.store_manager_status_field_value {
  text-align: right;
  font-family: LemonMilk;
}

.store_manager_table_body_cell_expansion_summary_content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.store_manager_table_body_cell_mobile {
  font-family: LemonMilkBold;
  border-bottom-color: $primary-black;
  border-bottom-width: 2px;
}

.store_manager_table_body_cell_mobile_2 {
  flex-shrink: 0;
}

.store_manager_store_status_cell {
  width: 180px;
}

.store_manager_action_button {
  font-family: LemonMilkBold;
  border-radius: 0;
  color: $primary-white;
  box-shadow: unset;
  width: 118px;
  height: 34px;
}

.store_manager_action_button_deactivate {
  background-color: #01a3ee;
}

.store_manager_action_button_pending {
  background-color: #ee5c01;
}

.store_manager_action_button_activate {
  background-color: unset;
  color: $primary-black;
  border: thick solid;
  border-width: 3px;
  border-color: $primary-black;
}

.store_manager_cell_text_primary {
  font-family: LemonMilkBold;
}

.store_manager_cell_text_secondary {
  font-family: Roboto-Medium;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.92px;
}