@import "shared/styles/variables.scss";

.grabbi_dialog_root {
  background-color: $primary-yellow;
  max-width: 90vw;
}

.grabbi_dialog_content {
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}