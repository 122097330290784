@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.VennDiagramRoot {
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  
  p {
    font-size: 24px;
    font-family: LemonMilkBold;
    width: 100%;
  }

  .CircleWrapper {
    width: 225px;
  }

  .JoinTextWrapper {
    align-self: center;
    width: 35px;
    z-index: 2;
  }

  .CircleContent {
    width: 180px;
    height: 180px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Circle {
    padding: 40px;
    border-radius: 200px;
    width: 200px;
    height: 200px;
  }

  .Circle1 {
    background-color: $primary-orange;
  }

  .Circle2 {
    background-color: $primary-purple;
    .CircleContent {
      padding-left: 20px;
    }
  }

  .JoinText {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 80px;
    height: 40px;
    background-color: $primary-black;
    color: $primary-white;
    padding: 5px 10px 5px 10px;
    border-radius: 25px;
    p {
      letter-spacing: 6px;
    }
    z-index: 2;
  }


}
