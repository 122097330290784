@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.time_series_chart_root {
  .dropdown {
    margin-right: 40px;
  }

  .filters {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .dropdown {
      margin-bottom: 10px;
    }
  }

  .chart {
    display: flex;
    margin-top: 30px;

    .line_wrapper {
      width: 0px;
      .line_svg {
        width: 66px;
        height: 100%;

        .line {
          stroke-dasharray: 5px;
          stroke-width: 3px;
          stroke: $primary-black;
          height: 100%;
        }
      }
    }

    .chart_rows {
      .chart_row {
        display: flex;
        margin-top: 40px;
        margin-bottom: 40px;
        .time_circle_wrapper {
          display: flex;
          
          .time_circle {
            background-color: $primary-yellow;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 40px;
            padding: 10px;
            border: 3px solid $primary-black;
            border-radius: 40px;
            font-family: LemonMilk;
          }
        }

        .items_area {
          padding-top: 7px;
          height: 100%;
          display: flex;
          margin-left: 20px;
          flex-wrap: wrap;

          .item_box {
            background-color: $primary-black;
            color: $primary-white;
            font-family: LemonMilk;
            margin-right: 20px;
            margin-bottom: 20px;
            padding: 15px 10px;
          }
        }
      }
    }
  }
}
