@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.card_selector_root {
  display: flex;
  flex-direction: column;
  align-items: center;
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    .tab {
      cursor: pointer;
      font-family: LemonMilkBold;
      font-size: 12px;
      color: $primary-black;
      margin-right: 20px;
      padding: 7px 10px;
    }

    .tab_selected {
      color: $primary-white;
      background-color: $primary-black;
      border-radius: 20px;
    }
  }

  .card_area {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 120px;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 3px solid $primary-black;
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 20px 10px 20px 10px;
      width: 200px;
      height: 100%;
      cursor: pointer;

      .card_content {
        font-style: normal;
        font-weight: bold;
        font-size: 23px;
        line-height: 27px;
        text-align: center;
      }

      .card_seehow {
        width: 100%;
        span {
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          text-align: center;
        }
      }
    }

    .card:hover {
      border: 3px solid $primary-blue;
      background-color: $primary-blue;
      color: $primary-white;

      .card_seehow {
        span {
          padding-bottom: 2px;
          border-bottom: 3px solid $primary-white;
        }
      }
    }
  }
}
