@import "shared/styles/variables.scss";

.username_button_root {
  padding: 0;
  color: $primary-black;
  font-family: LemonMilk;
  font-size: 16px;
  height: 40px;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: normal;
  padding-top: 10px;
  padding-bottom: 10px;
}

.MuiButton-root {
  min-width: unset;
}

.username_button_popover {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-direction: column;
  width: 150px;
  max-width: unset;
  height: 100px;
  background-color: $primary-black;
  border: 1px solid $primary-black;
  border-radius: 0;
  padding: 0px 12px;
}

.username_button_popover_button {
  align-content: center;
  font-family: LemonMilk;
  font-size: 14px;
  height: 23px;
  line-height: 23px;
  text-decoration: none;
  color: $primary-white;
}
