@import "shared/styles/variables.scss";

.excel_export_root {
  display: flex;
  align-items: center;
  height: 56px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: unset;
  border: unset;
  cursor: pointer;
  .excel_export_icon {
    height: 30px;
    color: $primary-black;
    
    background-repeat: no-repeat;
    background-position: center;
    min-height: 24px;
    min-width: 24px;
    margin-right: 15px;
  }

  div {
    font-family: LemonMilk;
  }

  &:hover, &:focus {
    background-color: rgba($primary-black, 0.2);
  }

  &:focus {
    border: unset;
    outline: unset;
  }
}
