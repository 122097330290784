@import "shared/styles/variables.scss";

.refund_dialog_root {
  .refund_dialog_content {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 50px;
  }

  .title {
    margin-bottom: 30px;
  }

  table {
    margin-bottom: 30px;
  }

  .action_area {
    .grabbi_button_root {
      margin-right: 30px;
    }

    .grabbi_button_root:nth-child(2) {
      background-color: unset;
      color: $primary-black;
      border: 3px solid $primary-black;
    }
  }
}
