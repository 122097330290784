@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.selection_state_chip_root {
  display: flex;
  align-items: center;
  background-color: $primary-white;
  border-radius: 25px;
  font-family: LemonMilk;
  width: 100%;
  height: 60px;
  border-radius: 25px;

  .quantity_container {
    margin-left: 1rem;
    display: flex;
    width: 2rem;
    height: 2rem;
    border: 1.5px solid $primary-black;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
  }

  .total {
    margin-left: 1rem;
  }
}