@import "shared/styles/variables.scss";

.header_root {
  max-width: 1280px;
  display: block;
  align-items: center;
  justify-content: space-between;
  background-color: $primary-yellow;
  box-shadow: unset;
  margin: 0 auto;
}

.header_dashboard {
  display: flex;
  justify-content: center;
  background-color: $primary-yellow;
  width: 100%;

  .content_container {
    width: 1280px;
    max-width: 90%;
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
  }
}

.header_icon_container,
.header_grabbi_mobile {
  padding: 18px 0;
  display: flex;
  float: left;
}

.header_center {
  width: 100%;
  display: block;
  align-items: center;
  justify-content: space-between;
}

.header_username {
  margin-left: 20px;
}

.header_icon {
  height: 42px;
  width: 42px;
  cursor: pointer;
  //background: url(/assets/grabbi.svg);
}

.header_right_section {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right;
  padding-top: 22px;
}

.header_link {
  text-decoration: none;
  cursor: pointer;
  color: $primary-black;
  font-family: 'LemonMilk';
  font-size: 16px;
  line-height: 23px;
  letter-spacing: normal;
  height: 23px;
  margin-left: 24px;
  padding-bottom: 1px;
}

.header_link_selected {
  border-bottom: 3px solid $primary-black;
}

.header_menu_paper {
  background-color: $primary-black;
  color: $primary-white;
  padding: 20px 20px 50px 20px;
  font-family: 'LemonMilkBold';
}

.header_menu_top {
  width: 100%;
  display: block;
  justify-content: flex-end;
}

.header_menu_icon_button_mobile {
  cursor: pointer;
}

.header_close_icon_mobile {
  color: $primary-white;
}

.header_menu_icon_mobile {
  color: $primary-black;
}

.header_menu_list_item {
  color: $primary-white;
}

.header_menu_list_item_selected {
  background-color: $primary-purple;
}

@media (max-width: $mobile-breakpoint) {
  .header_icon {
    display: none;
  }

  .header_menu_icon_button_mobile {
    cursor: pointer;
    float: right;
  }
}
