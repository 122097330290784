@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

@mixin border-bottom {
  border-bottom: 1px solid $primary-black;
}

@mixin content-padding {
  padding-left: 24px;
  padding-right: 24px;
}

.close_tab_dialog_root {
  .footer_container {
    background-color: $primary-yellow;
    border-top: 1px solid black;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    .action_button {
      height: 60px;
      width: 200px;
      margin: 0 10px;
      @include white-button();
    }
  }

  .close_tab_dialog_content {
    padding-top: 15px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    text-align: center;
    
    .confirmation_text_header_container {
      font: Roboto-Bold;
      font-weight: 700;
      font-size: 28px;
      line-height: 38px;
      width: 300px;
      max-width: 100%;
      
      margin-bottom: 20px;
    }

    .order_code_container {
      margin-bottom: 20px;
    }

    .confirmation_text_info_container {
      font-family: Roboto-Regular;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
}
