@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.order_card_root {
  border: 1px solid $primary-black;
  font-family: LemonMilk;

  .top_section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid $primary-black;
    padding: 1.2rem;

    .actions {
      display: flex;
      justify-content: flex-end;
    }
  }

  .middle_section {
    padding: 1.2rem;
  }

  .order_info_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .table_number_container {
      margin-bottom: 1rem;
    }
  }

  .order_info_middle {
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    padding-right: 1rem;
    //flex-wrap: wrap;

    .item_row {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      min-height: 3rem;

      .quantity_label_seperator {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

      .label_and_topping_container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        span {
          margin-right: 0.5rem;
        }
        .topping {
          margin-right: 0.3rem; 
          margin-top: 0.15rem;
          margin-bottom: 0.15rem;
        }
      }
    }
  }

  .order_info_right {
    display: flex;
    justify-content: flex-end;
    font-family: LemonMilkBold;
    @media (max-width: $mui-sm) {
      margin-top: 1rem;
    }

    .order_info_tip {
      font-size: 0.75rem;
    }
  }

  .bottom_section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (max-width: 350px) {
      justify-content: space-between;
    }
    border-top: 1px solid $primary-black;
    padding: 1.2rem;
  }

  .date_time {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
  }

  .print_button {
    height: 3rem;
    width: 3rem;
  }

  .print_button_collapsed {
    &::before {
      content: "Print";
    }
    background-image: unset;
    background-color: unset;
    margin-left: unset;
    display: unset;
    align-items: unset;
    color: $primary-black;
    background-repeat: unset;
    background-position: unset;
    justify-content: unset;
    cursor: unset;
  }

  .status_button {
    margin-left: 1.7rem;

    @media (max-width: 350px) {
      margin-left: 0.2rem;
    }
  }

  .refund_button {
    margin-left: 1.7rem;
  }

  .action_button {
    background-color: #dcd105;
    color: $primary-black;
  }

  .collapsed_actions {
    margin-left: 1.7rem;
    @media (max-width: 350px) {
      margin-left: 0.2rem;
    }

    @media (max-width: 301px) {
      margin-top: 1rem;
    }
  }

  .status_component {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: $primary-black;
    color: $primary-white;
    font-family: Roboto-Regular;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.7rem 1rem;

    svg {
      margin-right: 0.5rem;
    }
  }

  .status_component.red {
    background-color: red;
  }
}

.order_card_root.loading {
  opacity: 0.4;
  .loading_wrapper {
    height: 0;
    display: flex;
    justify-content: center;
    .loading_icon {
      margin-top: 3.5rem;
    }
  }
}
