@mixin background_image_icon {
  background-repeat: no-repeat;
  background-position: center;
}

.star_icon {
  @include background_image_icon;
  background-image: url('/assets/star-icon.svg');
}

.small_icon {
  width: 1.5rem;
  height: 1.5rem;
}

.medium_icon {
  width: 3rem;
  height: 3rem;
}

.large_icon {
  width: 5rem;
  height: 5rem;
}