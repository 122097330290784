@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.topping_wrapper {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid $primary-black;
    border-radius: 25px;
    font-family: LemonMilk;
    span {
      margin-right: 15px;
    }
  }