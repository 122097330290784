@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.open_tab_root {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .header {
    font-family: LemonMilk;
    padding: 1rem;
    position: sticky;
    top: 0;
    background-color: $primary-yellow;

    .store_title {}
  }

  .selection_body {
    overflow-y: scroll;
    flex: 1 1 auto;
  }

  .footer {
    background-color: $primary-yellow;
    position: sticky;
    bottom: 0;
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    .add_items_button {
      margin-left: 1rem;
      width: 300px;
      height: 60px;
    }

    .selection_state_chip,
    .add_items_button {
      @media (max-width: 380px) {
        height: 90px;
      }
    }
  }
}