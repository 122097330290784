@import "shared/styles/variables.scss";

.grabbi_table_root {
  padding-bottom: 50px;
}

.grabbi_table_table {
  border-color: $primary-black;
}

.grabbi_table_header {
  background-color: $primary-black;
  font-family: LemonMilk;
  letter-spacing: 1.08px;
  line-height: 19px;
  color: $primary-white;
  font-size: 0.875rem !important;
}

.grabbi_table_sort_label {
  color: $primary-white !important;
}

.grabbi_table_header_mobile {
  height: 36px;
  padding-top: 0;
  padding-bottom: 0;
}

.grabbi_table_body_cell {
  font-family: LemonMilk;
  border-bottom-color: $primary-black;
  border-bottom-width: 2px;
}

.grabbi_table_cell_no_border {
  border: unset;
}

.grabbi_table_row_expansion_panel {
  box-shadow: unset;
  background-color: $primary-yellow;
}

.grabbi_table_row_expansion_panel_summary {
  padding-left: 0;
  padding-right: 0;
}

.disabled_panel {
  opacity: unset !important;
  pointer-events: unset !important;
}

.disabled_panel > * {
  pointer-events: unset !important;
}

.grabbi_table_row_expansion_panel_icon {
  position: absolute;
  top: calc(100% - 25px);
}

.grabbi_table_row_expansion_details {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  padding-right: 0;
}

.grabbi_table_mobile_details_cell_header {
  width: 50%;
}

.grabbi_table_mobile_details_cell_body {
  width: 50%;
}

.grabbi_table_body_cell_mobile {
  font-family: LemonMilk;
  border-bottom-color: $primary-black ;
  border-bottom-width: 2px ;
  padding: 15px 0 !important;
  max-width: 0px; //? [REVISIT] I don't understand why I need this but vp width < 500px will not render well without it
}

.grabbi_table_body_cell_mobile_2 {
  flex-shrink: 0;
}

.grabbi_table_cell_text_primary {
  font-family: LemonMilk;
}

.grabbi_table_mobile_details_table_body_cell_border {
  border-top: 1px solid $primary-black;
  border-bottom: 1px solid $primary-black;
  padding: 10px 16px;
}

.grabbi_table_mobile_details_table_body_cell_no_border {
  border-bottom: unset;
  padding: 10px 16px;
}

.grabbi_table_mobile_summary_table_body_cell {
  border-bottom: unset;
  padding: 0 16px;
}
