@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.customer_order_root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .loading_state {
    font-family: LemonMilk;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    span {
      margin-bottom: 1rem;
    }
  }
}