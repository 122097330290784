@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.restaurant_board_root {
  .no_results_root {
    width: 100%;
  }

  .mock_data_warning {
    font-family: LemonMilk;
    display: flex;
    justify-content: center;
    width: 100%;
    border: 3px solid $primary-black;
    margin-bottom: 15px;
    padding: 10px;
  }

  .opaque_loading {
    opacity: 0.6;
  }

  .soft_loading_container {
    height: 0;
    display: flex;
    justify-content: center;
    width: 100%;

    .soft_loading_icon {
      margin-top: 50vh;
    }
  }

  .board_container {
    .board_filter_row_container {
      .board_filter_row {
        margin-left: 0;
        margin-right: 0;

        @media (max-width: $mui-md) {
          margin-top: 2rem;
          margin-bottom: 1rem;
        }
      }

      .board_filter_grid_item:first-child {
        padding-left: 0;
      }

      .board_filter_grid_item {
        @media (max-width: $mui-sm) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .board_action_row {
      display: flex;
      width: 100%;

      .grabbi_button_root {
        height: 3.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 1rem;

        @media (min-width: $mui-md) {
          margin-bottom: unset;
          font-size: 0.75rem;
          height: 50px;
        }
      }

      .show_closed_tabs_button {
        @media (min-width: $mui-md) {
          width: 8rem;
        }
      }
    }

    .board_column_row {
      @media (min-width: $mui-md) {
        margin-top: 1rem;
      }

      flex-wrap: nowrap;

      .board_column_header {
        display: flex;
        align-items: center;
        background-color: $primary-black;
        color: $primary-white;
        @include mid-text();
        height: 50px;
        padding-left: 23px;
      }

      .board_column_header:nth-child(2) {
        border-left: 4px $primary-yellow solid;
        border-right: 4px $primary-yellow solid;
      }
    }

    .table_info_row,
    .order_info_row,
    .item_card_columns {
      border-top: 1px solid $primary-black;
      border-left: 1px solid $primary-black;
      border-right: 1px solid $primary-black;


      .board_column_header {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-black;
        color: $primary-white;
        @include small-text();
        height: 2rem;
      }
    }

    .table_info_row,
    .order_info_row {
      @include flex-center();

      .expand_icon {
        cursor: pointer;
        padding: unset !important;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0.2);
        /*&:hover {
        background-color: rgba(0,0,0,0.2);
        border-radius: 25px;
      }*/
      }

      .expand_icon_flipped {
        transform: rotate(180deg);
      }
    }

    .table_info_row {
      height: 4rem;
      padding-left: 12px;

      @media (max-width: $mui-sm) {
        height: 5rem;
      }

      .table_number_container {
        @include flex-center();
        @include large-text();

        svg {
          font-size: 2rem;
          padding-right: 5px;
        }
      }

      .edit_button_container {
        @include flex-end();
        height: 3rem;

        @media (max-width: $mui-sm) {
          padding-right: 1rem;
        }

        .edit_button {
          display: flex;
          justify-content: space-between;
          font-size: 0.9rem;
          width: 12rem;

          @media (max-width: $mui-sm) {
            height: 3rem;
          }
        }

        .pencil_icon {
          background-image: url("/assets/pencil-icon.svg");
          background-repeat: no-repeat;
          background-position: center;
          height: 12px;
          width: 12px;
        }
      }
    }

    .table_info_row_collapsed {
      border-bottom: 1px solid $primary-black;
    }

    .order_info_row {
      height: 5rem;
      padding-left: 1rem;
      padding-right: 1rem;

      @media (max-width: $mui-md) {
        height: 7rem;
      }

      margin: unset;

      .order_code_root {
        @media (max-width: $mui-sm) {
          text-align: center;

          .order_number_container {
            margin-bottom: 0.6rem;
          }

          .ordered_on_container {
            margin-left: 0;
          }
        }
      }

      .action_button_container {
        .transaction_print {
          margin-left: unset !important;
          width: 100%;
          height: 3rem;

          @media (max-width: $mui-md) {
            height: 4rem;
          }
        }

        .payment_status_container {
          display: flex;
          justify-content: flex-end;

          .pos_button {
            overflow: hidden;
            height: 3rem;
            width: 100%;
            svg {
              width: 4rem;
            }
            @media (max-width: $mui-md) {
              height: 4rem;
            }
          }

          .payment_status {
            overflow: hidden;
            background-color: $primary-black;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primary-white;
            border-radius: 5px;
            height: 3rem;

            @media (max-width: $mui-md) {
              height: 4rem;
              padding-left: 0.5rem;
              padding-right: 0.5rem;
            }

            .dollar_icon {
              background-image: url("/assets/dollar-sign-icon.svg");
              background-repeat: no-repeat;
              background-position: center;
              height: 1.4rem;
              width: 1.2rem;
              padding-top: 0.7rem;
              padding-bottom: 0.7rem;
            }

            .google_icon {
              background-image: url("/assets/google-icon.svg");
              background-repeat: no-repeat;
              background-position: center;
              height: 20px;
              width: 20px;
            }

            .apple_icon {
              background-image: url("/assets/apple-icon.svg");
              background-repeat: no-repeat;
              background-position: center;
              height: 24px;
              width: 24px;
            }

            span {
              padding-left: 0.5rem;
              padding-right: 0.5rem;
              font-family: LemonMilk;
              font-size: 0.9rem;
              text-align: center;

              @media (max-width: $mui-md) {
                padding-left: 0.2rem;
                padding-right: 0.2rem;
              }
            }
          }

          .payment_status.unpaid {
            flex-wrap: wrap;
            background-color: $primary-white;
            color: $primary-black;

            .red {
              color: $primary-red;
            }
          }
        }



        .close_tab_button {
          width: 100%;
          height: 100%;
          font-family: LemonMilk;
          height: 3rem;

          @media (max-width: $mui-md) {
            height: 4rem;
          }
        }
      }
    }

    .dragging_column {
      background-color: rgba($primary-black, 0.2) !important;
    }

    .board_column {
      min-height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;

      $verticle-padding: 1.2rem;
      $horizontal-paddding: 1.2rem;

      .board_column_padded_area {
        padding-top: $verticle-padding;
        padding-bottom: $verticle-padding;

        @media (max-width: $mui-sm) {
          border-bottom: 1px solid $primary-black;
          padding-left: $horizontal-paddding;
          padding-right: $horizontal-paddding;
          padding-left: 0.3rem;
          padding-right: 0.3rem;
        }
      }

      .board_column_padded_area.remove_bottom_padding {
        @media (max-width: $mui-sm) {
          border-bottom: unset;
        }
      }

      @media (min-width: $mui-sm) {
        .board_column_padded_area {
          padding-left: $horizontal-paddding;
          padding-right: $horizontal-paddding;
        }
      }





      &:nth-child(2) {
        @media (min-width: $mui-sm) {
          border-left: 1px solid $primary-black;
          border-right: 1px solid $primary-black;
        }
      }

      .item_card_root {
        margin-bottom: 10px;
      }

      .item_card_row.MuiGrid-grid-xs-12 {
        flex-basis: unset !important;
      }


      .item_card_print_button {
        cursor: pointer;
        background-color: $primary-white;
        height: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .icon {
          height: 1.5rem;
          width: 1.5rem;
          background-image: url("/assets/grabbi-print-icon-alt.svg");
          background-repeat: no-repeat;
          background-position: center;
        }

        span {
          font-family: LemonMilk;
          font-size: 12px;
        }
      }

      .item_card_print_button.disabled {
        cursor: default;
        background-color: rgba($primary-black, 0.3);

        .icon {
          background-image: url("/assets/grabbi-print-icon-alt-faded.svg");
        }

        span {
          color: $primary-white;
        }
      }

      .add_items_button {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-top: 1px solid $primary-black;
        border-bottom: 1px solid $primary-black;
        padding-top: 11px;
        padding-bottom: 11px;

        .add_icon {
          background-image: url("/assets/add-icon.svg");
          height: 24px;
          width: 24px;
          background-repeat: no-repeat;
          background-position: center;
          margin-right: 10px;
        }

        .add_items_text {
          @include mid-text();
        }
      }
    }

    .move_items_card {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      padding-left: 0.7rem;
      align-self: flex-end;
      line-height: 2rem;

      .MuiSvgIcon-root {
        height: 2rem;
        width: 2rem;
      }
    }

    .item_card_columns:last-child {
      border-bottom: 1px solid $primary-black;
    }
  }

  .transactions_loading_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.resturant_dashboard_dialog {
  height: 100%;
  width: 100%;
  @media (max-width: $mui-md) {
    max-height: unset;
    max-width: unset;
    margin: unset;
  }
}