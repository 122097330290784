@import "shared/styles/variables.scss";

.tos_root {
  max-width: $desktop-max-width;
  flex: 1;
  padding: 20px 80px;
  box-sizing: border-box;
}

.tos_header {
  font-family: LemonMilkBold;
  margin-bottom: 50px;
}

.tos_subheader {
  font-family: Roboto-Bold;
  font-size: 1.25rem;
  margin-top: 24px;
  margin-bottom: 24px;
  padding-left: 10px;
}

.tos_paragraph {
  font-family: Roboto-Light;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 24px;
}

.tos_bold_text {
  font-family: Roboto-Medium;
}

.tos_text_link {
  color: $primary-black;
  text-decoration: underline;
  cursor: pointer;
}

.tos_table_of_contents {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  font-family: Roboto-Bold;
  color: $primary-black;
}

.tos_table_of_contents_item {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  font-family: Roboto-Bold;
  color: $primary-black;
  padding-left: 10px;
}

.tos_table_of_contents_list_item {
  font-family: Roboto-Bold;
  color: $primary-black;
  padding-left: 0;
  padding: 10px 0;
  font-size: 20px;
}

.tos_table_of_contents_ordered_list {
  list-style-position: inside;
  padding-left: 0;
}

.tos_list_item {
  font-family: Roboto-Bold;
  color: $primary-black;
  font-size: 20px;
}

.tos_ordered_list {
  list-style-position: inside;
  padding-left: 0;
}