@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.topping_chip_root {
  border: 1px solid $primary-black;
  border-radius: 25px;
  padding: 1rem 1rem;
  flex-shrink: 1;
  cursor: pointer;

  .topping_label_text {
    font-family: LemonMilk;
    font-size: 1rem;
    white-space: nowrap;
  }

  .merchandise_text_status_warning {
    margin-left: 5px;
    font-size: 0.7rem;
    font-family: LemonMilk;
  }
}

.topping_chip_root.disabled {
  opacity: 0.7;
  cursor: default;
}
