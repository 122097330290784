@import "shared/styles/variables.scss";

.admin_console_main {
  padding-top: 30px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
}

.admin_console_main_mobile {
  padding-top: unset;
  flex-direction: column;
}

.admin_console_switch_content {
  width: 100%;
  height: 100%;
  display: flex;
}

.admin_console_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  width: 100%;
  min-height: 100%;
}
