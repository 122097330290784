@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.item_card_root.clickable {

  &:hover,
  &:focus {
    outline: 2px solid $primary-black;
  }

  cursor: pointer;
}

.item_card_root {
  padding: 20px 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .item_card_content_top {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .left {
      .title_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px;
        width: 100%;

        div {
          display: flex;
          align-items: center;
          white-space: pre-line;
          overflow: hidden;

          .quantity {
            font-family: LemonMilk;
            font-size: 1.3rem;
            padding-right: 0.5rem;
          }

          .separator {
            font-family: LemonMilk;
            font-size: 1.0rem;
            padding-right: 0.5rem;
          }

          .label {
            font-family: LemonMilkBold;
            font-size: 1.1rem;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .right {
      .checkbox_icon {
        background-image: url("/assets/checkbox.svg");
        background-repeat: no-repeat;
        background-position: center;
        height: 24px;
        width: 24px;

        @media (max-width: 756px) {
          display: none;
        }

        @media (max-width: $mui-sm) {
          display: block;
        }
      }
      
      .price {
        font-family: LemonMilk;
        font-size: 1.3rem;
      }
    }
  }

  .toppings_container {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 1;

    .topping_wrapper {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

.item_card_root.item_card_root_white {
  background-color: $primary-white;
}

.item_card_root.item_card_root_blue {
  background-color: $primary-blue;
  color: $primary-white;

  .topping_wrapper {
    border-color: $primary-white;
  }

  .loading_icon_root {
    color: $primary-white;
  }
}

.item_card_root.item_card_root_green {
  background-color: $primary-green;
  color: $primary-white;

  .topping_wrapper {
    border-color: $primary-white;
  }

  .loading_icon_root {
    color: $primary-white;
  }
}

.action_card_root.item_card_root_white {
  background-color: $primary-purple;
  color: $primary-white;
}

.action_card_root {
  border-radius: 4px;
  width: unset;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  height: unset;
  padding: 0;
  display: flex;
  flex-direction: column !important;

  .children_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.action_card_root.loading {
  padding-right: 1rem;

  .loading_wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 0;

    .loading_icon {
      color: white;
    }
  }

  .children_wrapper {
    opacity: 0.6;
  }
}