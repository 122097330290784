@import "shared/styles/variables.scss";

.grabbi_divider_root {
  display: flex;
  align-items: center;
  width: 100%;

  .line {
    flex: 1 0 auto;
    border-bottom: 3px solid;
  }
}

.grabbi_divider_root_gray {
  $gray: #424242;
  border-color: $gray;
  
  .line {
    opacity: 0.5;
  }
}