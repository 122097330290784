@import "shared/styles/variables.scss";

.privacy_root {
  max-width: $desktop-max-width;
  flex: 1;
  padding: 20px 80px;
  box-sizing: border-box;
}

.privacy_header {
  font-family: LemonMilkBold;
  font-size: 3rem;
  margin-bottom: 50px;
}

.privacy_subheader {
  font-family: Roboto-Bold;
  font-size: 1.25rem;
  margin-bottom: 24px;
}

.privacy_paragraph {
  font-family: Roboto-Light;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
  margin-bottom: 24px;
}

.privacy_bold {
  font-family: Roboto-Medium;
}

.privacy_link {
  color: $primary-black;
  text-decoration: underline;
  cursor: pointer;
}