@import "shared/styles/variables.scss";

.grabbi_pagination_root {
  border: none;

  .grabbi_pagination_font {
    font-family: LemonMilk;
  }
}

.MuiPopover-root {
  .grabbi_pagination_font {
    font-family: LemonMilk;
  }
}