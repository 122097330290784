@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.store_dashboard_root {
  @include dashboard_content;

  title {
    font-family: LemonMilkBold;
    font-size: 36px;
    line-height: 62px;
    letter-spacing: normal;
    text-align: left;
  }

  .dropdown {
    margin-top: 20px;
    margin-bottom: 1rem;
    width: 500px !important;
    margin-bottom: 1rem;

    @media (max-width: $mobile-breakpoint) {
      width: 100% !important;
    }
  }
}
