@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

@mixin border-bottom {
  border-bottom: 1px solid $primary-black;
}

@mixin content-padding {
  padding-left: 24px;
  padding-right: 24px;
}

@mixin min-row-height {
  min-height: 80px;
}

.header_buttons_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}

.restaurant_dialog_header_container {
  .change_table_button,
  .store_attendant {
    height: 3rem;
    width: 220px;
  }

  .change_table_button_container {
    margin-right: 50px;
  }
}

.edit_table_dialog_root {
  .edit_table_dialog_content {
    padding-left: 0;
    padding-right: 0;

    @media (max-height: 250px) {
      height: 100px;
    }
  }

  .change_table_form {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 50px 25px;
    margin-bottom: 0;
    @media (max-height: 250px) {
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
    }

    .text_field_root {
        margin-bottom: 0 !important;
      }
  }

  .move_table_button {
    height: 50px;
    width: 150px;
  }

  .move_table_button:first-child {
    margin-right: 15px;
  }

  .move_table_button:last-child {
    margin-left: 15px;
  }

  .order_header_container {
    @include border-bottom();
    @include content-padding();
    @include min-row-height();
    display: flex;
    align-items: center;
  }

  .transaction_item_container {
    border-bottom: 1px solid $primary-black;
    @include content-padding();
    min-height: 40px;
    flex: 1 0 auto;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:last-child() {
      border-bottom: unset;
    }

    .title_container {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      max-width: 80%;
      padding: 0 5px;
      div:first-child {
        display: flex;
        align-items: center;

        .quantity {
          font-family: LemonMilk;
          font-size: 1.3rem;
          padding-right: 1.3rem;
        }
        .label {
          font-family: LemonMilkBold;
          font-size: 1.1rem;
        }
      }
    }

    .toppings_container {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 1;
    }

    .delete_button {
      background-image: url("/assets/delete-icon.svg");
      height: 2rem;
      width: 2rem;
      border-radius: 25px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .confirmation_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include content-padding();

    .confirmation_large_text_container {
      margin-top: 2rem;
      text-align: center;
      font-size: 1.4rem;
      font-family: Roboto-Bold;
    }

    .confirmation_small_text_container {
      margin-top: 2rem;
      @include small-text();
    }
  }

  .loading_container {
    border-top: 1px solid $primary-black;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .footer_container {
    border-top: 1px solid $primary-black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    .confirm_button {
      margin-left: 12px;
      margin-right: 12px;
      width: 150px;
      height: 50px;
      @include white-button();
    }
  }
}
