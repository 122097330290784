.x-large-font {
  font-size: 2.5rem;
}

.large-font {
  font-size: 1.5rem;
}

.normal-font {
  font-size: 1rem;
}

.small-font {
  font-size: 0.75rem;
}

.x-small-font {
  font-size: 0.5rem;
}

.lemon-milk-font {
  font-family: 'LemonMilk';
}

.lemon-milk-bold-font {
  font-family: 'LemonMilkBold';
}

.font-weight-1 {
  font-weight: 500;
}

.font-weight-2 {
  font-weight: 600;
}

.font-weight-3 {
  font-weight: 700;
}