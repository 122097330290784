@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.merchandise_list_root {
  .menu_category_container {
    @include content-padding();
    padding-top: 20px;
    padding-bottom: 20px;
    @include mid-text();
    background-color: #d3c800;
  }

  .merchandise_row {
    padding: 0.5rem 1rem;

    .merchandise_container {
      background-color: $primary-white;
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      cursor: pointer;

      .merchandise_checkbox {
        color: $primary-black;
      }

      .top_row {
        display: flex;
        .left_side {
          overflow: hidden;
          word-wrap: break-word;
        }
        .right_side {
          margin-left: 1.5rem;
        }
      }

      

      .merchandise_text {
        font-family: LemonMilk;
        font-size: 1rem;
        padding-right: 0.5rem;
        
      }

      .merchandise_text_status_warning {
        margin-left: 5px;
        font-size: 0.7rem;
        font-family: LemonMilk;
      }

      .add_toppings {
        border: 3px solid $primary-black;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        font-family: LemonMilk;
        margin-left: auto;
        max-width: 100px;
        text-align: center;
      }

      .add_toppings.checked {
        border-color: $primary-white;
      }

      .Mui-checked {
        color: $primary-white;
      }

      .top_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 2rem);
        padding-left: 1rem;
        padding-right: 1rem;

        .right_side {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;

          .price_container {
            font-family: LemonMilk;
          }
        }


      }

      .bottom_row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: calc(100% - 2rem);
        padding-left: 1rem;
        padding-right: 1rem;

        .select_button {
          margin-top: 2rem;
          padding: 1rem 3rem;
          max-width: 22rem;
        }
      }
    }
  }



  .merchandise_container.expanded {
    cursor: default;
    color: $primary-white  !important;

    .error_message {
      background-color: $primary-red;
      color: $primary-white;
      padding: 0 0.5rem;
      border-radius: 5px;
    }

    .top_row {
      cursor: pointer;
    }
  }
}