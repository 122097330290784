@import "shared/styles/variables.scss";

.merchandise_form_root {
  display: flex;
  flex-direction: column;

  .warning {
    font-family: Roboto-Black;
  }

  .form_area {
    width: 400px;
    .field,
    .text_field_root,
    .grabbi_dropdown_wrapper {
      width: 100%;
    }
  }

  .grabbi_dropdown_wrapper {
    margin-bottom: 24px;
  }

  .action_area {
    margin-top: 30px;
    display: flex;

    .grabbi_button_root {
      margin-right: 30px;
      height: 50px;
      width: 200px;
    }

    .grabbi_button_root:nth-child(2) {
      background-color: unset;
      color: $primary-black;
      border: 3px solid $primary-black;
    }
  }

  .category_selection_container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .grabbi_dropdown_wrapper {
      margin-bottom: 0;
    }
  }

  .add_category_button {
    width: 70px;
    margin-left: 30px;
    height: 60px;
  }

  .loading {
    justify-content: center;
  }
}
