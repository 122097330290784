$spacing-1: 0.25rem;
$spacing-2: 0.5rem;
$spacing-3: 0.75rem;
$spacing-4: 1rem;
$spacing-5: 1.5rem;
$spacing-6: 2rem;
$spacing-7: 4rem;
$spacing-8: 10rem;

.full-width {
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-column-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex-align-self-center {
  align-self: center;
}

.flex-space-between-center {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.m-1 {
  margin: $spacing-1;
}

.m-2 {
  margin: $spacing-2;
}

.m-3 {
  margin: $spacing-3;
}

.m-4 {
  margin: $spacing-4;
}

.m-5 {
  margin: $spacing-5;
}

.m-6 {
  margin: $spacing-6;
}

.m-7 {
  margin: $spacing-7;
}

.m-8 {
  margin: $spacing-8;
}

.m-t-1 {
  margin-top: $spacing-1;
}

.m-t-2 {
  margin-top: $spacing-2;
}

.m-t-3 {
  margin-top: $spacing-3;
}

.m-t-4 {
  margin-top: $spacing-4;
}

.m-t-5 {
  margin-top: $spacing-5;
}

.m-t-6 {
  margin-top: $spacing-6;
}

.m-t-7 {
  margin-top: $spacing-7;
}

.m-t-8 {
  margin-top: $spacing-8;
}

.m-b-1 {
  margin-bottom: $spacing-1;
}

.m-b-2 {
  margin-bottom: $spacing-2;
}

.m-b-3 {
  margin-bottom: $spacing-3;
}

.m-b-4 {
  margin-bottom: $spacing-4;
}

.m-b-5 {
  margin-bottom: $spacing-5;
}

.m-b-6 {
  margin-bottom: $spacing-6;
}

.m-b-7 {
  margin-bottom: $spacing-7;
}

.m-b-8 {
  margin-bottom: $spacing-8;
}

.p-x-1 {
  padding-left: $spacing-1;
  padding-right: $spacing-1;
}

.p-x-2 {
  padding-left: $spacing-2;
  padding-right: $spacing-2;
}

.p-x-3 {
  padding-left: $spacing-3;
  padding-right: $spacing-3;
}

.p-x-4 {
  padding-left: $spacing-4;
  padding-right: $spacing-4;
}

.p-x-5 {
  padding-left: $spacing-5;
  padding-right: $spacing-5;
}

.p-x-6 {
  padding-left: $spacing-6;
  padding-right: $spacing-6;
}

.p-x-7 {
  padding-left: $spacing-7;
  padding-right: $spacing-7;
}

.p-x-8 {
  padding-left: $spacing-8;
  padding-right: $spacing-8;
}

.p-y-1 {
  padding-top: $spacing-1;
  padding-bottom: $spacing-1;
}

.p-y-2 {
  padding-top: $spacing-2;
  padding-bottom: $spacing-2;
}

.p-y-3 {
  padding-top: $spacing-3;
  padding-bottom: $spacing-3;
}

.p-y-4 {
  padding-top: $spacing-4;
  padding-bottom: $spacing-4;
}

.p-y-5 {
  padding-top: $spacing-5;
  padding-bottom: $spacing-5;
}

.p-y-6 {
  padding-top: $spacing-6;
  padding-bottom: $spacing-6;
}

.p-y-7 {
  padding-top: $spacing-7;
  padding-bottom: $spacing-7;
}

.p-y-8 {
  padding-top: $spacing-8;
  padding-bottom: $spacing-8;
}