@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.change_tip_root {
  .tip_selection_container {
      display: flex;
      margin-bottom: 30px;
  
      .grabbi_button_root {
        height: 75px;
        width: 100%;
      }
  
      margin-left: 0;
      margin-right: 0;
  
      @media (max-width: $mui-sm) {
        padding-right: 0.3rem;
      }
    }
  
    .tip_amount_field {
      height: 80px;
    }

  .confirmation_text_header_container {
    font: Roboto-Bold;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  @media (max-height: 560px) {
    .confirmation_text_info_container:last-child {
      margin-bottom: 0;
    }
  }
}