@import "shared/styles/variables.scss";

.success_message_root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success_message_icon {
  background-image: url(/assets/grabbi-success-icon.svg);
  width: 166px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  margin-top: 61px;
}

.success_message_header {
  font-size: 3.25rem;
  line-height: 3.875rem;
  letter-spacing: normal;
  font-family: LemonMilkBold;
}

.success_message_sub_header {
  padding-top: 12px;
  font-size: 1rem;
  line-height: 1.2rem;
  letter-spacing: normal;
  font-family: LemonMilkBold;
}

.success_message_navigate_to_button {
  margin-top: 30px;
  background-color: $primary-purple;
  color: $primary-white;
  font-family: LemonMilkBold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: normal;
  height: 56px;
  border-radius: 4px;
  width: 300px;
}
