@import "shared/styles/variables.scss";

.merchant_menu_root {
  table {
    .MuiTableRow-root {
      td {
        font-size: 24px;
      }
    }
  }

  .action_bar {
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
    margin-top: 30px;
    margin-bottom: 30px;

    .grabbi_button_root {
      height: 50px;
      width: 100px;
    }

    @media (max-width: $mobile-breakpoint) {
      justify-content: center;
      .grabbi_button_root {
        width: 90%;
      }
    }
  }

  .tabs {
    margin-bottom: 20px;
  }

  .tabs_mobile {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .transaction_update_loading_wrapper {
    height: 39.33px;
    border-bottom: 2px solid $primary-black;
    background-color: $primary-black;
    color: $primary-white;
    text-align: center;
    font-family: LemonMilkBold;
    font-size: 20px;

    .transaction_update_flex_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .transaction_update_loading {
      margin-left: 20px;
      height: 39px !important;
      width: 39px !important;
      color: $primary-white;
    }
  }

  .checkmark_header {
    height: 24px;
    width: 24px;
    margin-left: 13px;
    background-image: url(/assets/checkmark.svg);
    background-repeat: no-repeat;
  }

  .edit_button {
    text-decoration: none;
    .grabbi_button_root {
      height: 50px;
      width: 100px;
    }
  }

  .checkbox .MuiSvgIcon-root {
    color: $primary-black;
    width: 30px;
    height: 30px;
  }

  .loading_root {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .no_results_root {
    padding-top: 108px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .message {
      width: 425px;
      text-align: center;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 1.33px;
      font-family: LemonMilk;
      color: #131313;
    }

    .header {
      padding-bottom: 14px;
      font-size: 27px;
      line-height: 38px;
      letter-spacing: normal;
      font-family: LemonMilkBold;
    }
  }

  .merchant_menu_table {
    $mobile_bp: 800px;

    .name_column {
      width: 80%;
    }

    .button_column {
      width: 20%;
    }

    .button_wrapper {
      display: flex;
      flex-direction: row;

      @media (max-width: $mobile_bp) {
        flex-direction: column;
        justify-content: center;
      }
    }

    .button_wrapper > * {
      margin-left: 40px;

      @media (max-width: $mobile_bp) {
        flex-direction: column;
        justify-content: center;
        margin-left: unset;
        margin-bottom: 40px;
      }
    }
  }
}
