@import "shared/styles/variables.scss";

.no_stores_root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no_stores_header {
  padding-top: 108px;
  font-size: 27px;
  line-height: 38px;
  letter-spacing: normal;
  font-family: LemonMilkBold;
}

.no_store_add_store_button {
  margin-top: 30px;
  background-color: $primary-purple;
  color: $primary-white;
  font-family: LemonMilkBold;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: normal;
  height: 56px;
  border-radius: 4px;
  width: 300px;
}