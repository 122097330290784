@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

@mixin border-bottom {
  border-bottom: 1px solid $primary-black;
}

@mixin content-padding {
  padding-left: 24px;
  padding-right: 24px;
}

@mixin min-row-height {
  min-height: 80px;
}

.open_tab_dialog_root {
  .footer_container {
    background-color: $primary-yellow;
    border-top: 1px solid black;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    .add_items_button {
      margin-left: 1rem;
      height: 60px;
      width: 400px;
    }

    .error_message {
      color: $primary-red;
      font-family: LemonMilk;
      margin-bottom: 1rem;;
    }
  }

  .open_tab_dialog_content {
    padding-top: 15px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;

    .text_field_root {
      margin-bottom: 0 !important;
    }

    @media (max-height: 250px) {
      padding-top: 0;
      padding-bottom: 0;
      height: 100px;
    }

    .merchandise_selector_root {
      width: 100%;
    }
  }
}