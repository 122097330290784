@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.analytics_component_root {
  width: 100%;
  align-self: center;

  padding-top: 60px;
  .padded {
    padding-left: 50px;
    padding-right: 50px;
  }

  $content-width: 1000px;

  .chart {
    height: 100% !important;
    width: $content-width !important;
    max-width: 100% !important;
  }

  .title_area {
    width: $content-width !important;
    max-width: 100% !important;
  }

  .improve_profits {
    margin-top: 80px;
    .title_area {
      text-align: center;
      p {
        margin-bottom: 30px;
      }
    }
  }

  .content_aligned {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .product_pairings {
    background-color: $primary-black;
  }

  .customer_favs {
    margin-top: 150px;
  }

  .subtitle {
    margin-top: 20px;
    margin-bottom: 40px;
    font-family: LemonMilkLight;
  }

  .sales {
    margin-top: 100px;
  }

  .consider_pairing {
    background-color: $primary-black;
    color: $primary-white;
  }

  .top_seller {
    margin-top: 70px;
  }

  .top_seller_chart {
    margin-top: 70px;
  }

  .visitors_chart {
    margin-top: 70px;
    width: $content-width;
  }

  .centered_text {
    text-align: center;
  }

  .holidays {
    margin-top: 100px;
    background-color: $primary-black;
    padding-top: 70px;
    padding-bottom: 70px;

    .title {
      color: $primary-white;
    }

    .holiday_activity {
      width: $content-width;
      max-width: 80%;
    }
  }
}
