@import "shared/styles/variables.scss";

@font-face {
  font-family: "LemonMilk";
  src: url(./fonts/LemonMilk.woff2) format("woff");
}

@font-face {
  font-family: "LemonMilkBold";
  src: url(./fonts/LemonMilkBold.woff2) format("woff");
}

@font-face {
  font-family: "LemonMilkLight";
  src: url(./fonts/LemonMilkLight.woff2) format("woff");
}

@font-face {
  font-family: "LemonMilkItalic";
  src: url(./fonts/LemonMilkItalic.woff2) format("woff");
}

@font-face {
  font-family: "Roboto-Medium";
  src: url(./fonts/Roboto-Medium.woff2) format("woff");
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(./fonts/Roboto-Bold.woff2) format("woff");
}

@font-face {
  font-family: "Roboto-Light";
  src: url(./fonts/Roboto-Light.woff2) format("woff");
}

@font-face {
  font-family: "Roboto-Black";
  src: url(./fonts/Roboto-Black.woff2) format("woff");
}

@font-face {
  font-family: "Roboto-Regular";
  src: url(./fonts/Roboto-Regular.woff2) format("woff");
}

.grabbi-snackbar {
  &-success {
    background-color: $primary-blue !important;
  }

  &-info {
    background-color: $primary-black !important;
  }

  &-error {
    background-color: $primary-red !important;
  }

  &-warning {
    background-color: $primary-orange !important;

    div {
      svg {
        color: $primary-orange;
        background-color: $primary-white;
        height: 16px;
        width: 16px;
        padding: 1px;
        border-radius: 14px;
      }
    }
  }
}

.grabbi-snackbar-success {
  .item_status_undo_button {
    cursor: pointer;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}
