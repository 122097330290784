@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.my_tab_root {
  width: 100%;
  .tab_header_container {
    border-bottom: 3px solid $primary-black;
    margin-bottom: 1rem;
    padding-bottom: 0.7rem;

    span {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }

  .tab_items_container {
    overflow-y: scroll;
    margin-bottom: 1.5rem;

    .item_card_root:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}