@import "shared/styles/variables.scss";

.confirm_merchant_root {
  height: calc(100vh * 0.5);
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header {
    font-family: LemonMilk;
    font-weight: bold;
    line-height: 62px;
    font-size: 36px;
    margin-bottom: 20px;
  }

  .subtext {
    font-family: LemonMilk;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
  }

  .button {
    width: 300px;
  }
}