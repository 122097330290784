@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.merchant_dashboard_root {
  @keyframes blinking {
    0% {
      background-color: #00A3FF;
    }
    100% {
      background-color: #004E7A;
    }
  }

  .blink {
    width: 85%;
    height: 45px;
    animation: blinking 1s infinite;
    text-align: center;
    position: absolute;
    top: 0;
    cursor: pointer;
  }

  .new_order_title {
    color: $primary-white;
    font-family: LemonMilkBold;
    font-size: 28px;
    font-weight: bold;
  }

  .no_results_root {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 108px;
  }

  .loading_root {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .action_bar {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    height: 4rem;

    @media (max-width: $mui-sm) {
      justify-content: space-between;
    }

    .more_action_button {
      margin-left: 1rem;
      width: 7rem;
    }
  }

  .tabs {
    margin-bottom: 20px;
  }

  .tabs_mobile {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .order_card {
    margin-bottom: 2rem;
  }
}
