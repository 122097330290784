@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

@mixin min-row-height {
  min-height: 80px;
}

.add_table_dialog_root {
  .add_table_dialog_content {
    padding-left: 0;
    padding-right: 0;
  }

  .footer_container {
    background-color: $primary-yellow;
    border-top: 1px solid black;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    .add_items_button {
      margin-left: 1rem;
      height: 60px;
      width: 400px;
    }
  }

  .loading_container {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .loading_soft_container {
    display: flex;
    justify-content: center;
    height: 0;
    width: 100%;
    z-index: 2;

    .loading_soft_icon {
      margin-top: 40vh;
    }
  }

  .opaque_loading {
    opacity: 0.6;
  }
}
