@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.grabbi-print-button {
    margin-left: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 30px;
    width: 30px;
    background-color: $primary-purple;
    cursor: pointer;
    background-image: url("/assets/print-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    height: 24px;
    width: 24px;
}

.grabbi-print-button.black {
  background-image: url("/assets/print-icon-black.svg");
}
