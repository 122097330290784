@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.hot_seller_root {
  width: 100%;
  .weekday_choices {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .weekday_choice {
      font-family: LemonMilkBold;
      cursor: pointer;
      padding: 10px 15px;
      border-radius: 25px;
    }
    .weekday_choice:hover {
      background-color: rgba(0,0,0,0.1);
    }
    .selected {
      background-color: $primary-black;
      color: $primary-white;
    }
  }
}