@import "shared/styles/variables.scss";

$column-breakpoint: 960px;

.about_root {
  background-color: $primary-white;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $column-breakpoint) {
    flex-direction: column;
  }
  padding-bottom: 50px;
}

.about_root_mobile {
  flex-direction: column;
  background-color: $primary-white;
}

.about_text {
  width: 420px;
}

.about_header {
  font-family: LemonMilkBold;
  font-size: 4.8rem;
  line-height: 5.5rem;
}

.about_body {
  padding-top: 40px;
  font-family: Roboto-Light;
  font-size: 1rem;
  letter-spacing: 0.34px;
  line-height: 1.2rem;
  font-weight: bold;
}

.about_body > a {
  padding-left: 4px;
  text-decoration: underline;
  cursor: pointer;
  font-family: Roboto-Medium;
  color: $primary-black;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: bold;
}

.about_image_wrapper {
  padding-left: 40px;
}