@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.order_placed_root {
  padding: 4rem 1.5rem 5rem 1.5rem;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: $primary-yellow;

  .header_container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .title {
      margin-bottom: 1rem;
      font-family: unset;
      text-transform: unset;
      font-weight: 700;
      font-size: 3rem;
    }

    .subtitle {
      margin-bottom: 1rem;
      font-family: unset;
      text-transform: unset;
      font-weight: 600;
      font-size: 1.4rem;
    }
  }

  .order_more_container {
    width: 100%;

    .grabbi_button_root {
      min-width: 100%;
      height: 5rem;
      font-size: 1.7rem;
      font-family: unset;
      text-transform: unset;
      font-weight: 700;
    }

    margin-bottom: 3rem;
  }

  .footer {
    margin-top: auto;

    .close_tab_button {
      font-size: 2rem;
      width: 100%;
      height: 68px;
      border-radius: 100px;
    }
  }
}