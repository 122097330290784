@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";
@import "shared/styles/ResturantBoardMixins.scss";

.cart_item_root {
  .left {
    display: flex;
    flex-direction: column;

    .price {
      font-family: LemonMilkBold;
    }

    .tax,
    .label,
    .description {
      font-family: LemonMilkLight;
      font-size: 0.8rem;
    }

    .tax {
      span:first-child {
        margin-right: 0.5rem;
      }
    }

  }

  .right {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    .quantity_selector {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      font-size: 1.5rem;

      .quantity_amount {
        margin-left: 1rem;
        margin-right: 1rem;
        font-family: LemonMilk;
      }

      .quantity_button {
        cursor: pointer;
        color: $primary-black;
        height: 1.75rem;
        width: 1.75rem;
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;

    .group {
      display: flex;
      flex-direction: row;
      flex-shrink: 1;
      
      .topping_chip_root {
          
      }
    }

    .group.required {

    }

    .group.optional {

    }
  }
}