@import "shared/styles/variables.scss";
@import "shared/styles/classes.scss";

.toppings_table_root {
  .grabbi_dialog_root {
    width: 800px;
  }

  .text_icon_row {
    margin-bottom: 30px;
  }

  .content_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 50px;
  }

  .toppings_table_table {
    margin-bottom: 50px;
    .MuiTableRow-root {
      th {
        .checkmark_header {
          height: 24px;
          width: 24px;
          margin-left: 13px;
          background-image: url(/assets/checkmark.svg);
          background-repeat: no-repeat;
        }
      }
      td {
        font-size: 22px;
        svg {
          width: 30px;
          height: 30px;
          color: $primary-black;
        }
        .loading {
          svg {
            color: $primary-white;
          }
        }
      }
    }
  }

  .grabbi_button_root {
    justify-self: flex-start;
  }
}
